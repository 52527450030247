import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";

// Components
import {
  Header,
  Body,
  Button,
  CentralModal,
  Card,
} from "../../../components/UI";
import { Heading2, Paragraph } from "../../../components/typography";
import FeaturesItem from "../../../components/lists/payment/features/Features-item";

// Containers
import FeaturesForm from "../../forms/payment/features/Features-form";

// Form Models
import { FeatureFormModel } from "../../../models/app/form";

// Domain Models
import { Feature } from "../../../models/app/domain/Feature";

// Calls
import {
  getSubscriptionFeaturesRequest,
  createSubscriptionFeatureRequest,
  updateSubscriptionFeatureRequest,
  deleteSubscriptionFeatureRequest,
} from "../../../api/calls/payment";

// Builders
import { subscriptionFeaturesTableHead } from "../../../builders/payment.builder";
import * as buttonDesignType from "../../../builders/button-design.types";

// enums
import { ResponseStatusType } from "../../../enums/api";

// Utils
import { errorHandler } from "../../../utils/error.utils";

// Styles
import { SubscriptionFeaturesStyles } from "./subscription-features.styles";

interface FeaturesState {
  features: Feature[];
  modalType?: string;
  chosenFeature?: Feature;
}

const SubscriptionFeatures = () => {
  const [featuresState, setFeaturesState] = useState<FeaturesState>({
    features: [],
    modalType: undefined,
    chosenFeature: undefined,
  });

  const getSubscriptionFeatures = async () => {
    const response = await getSubscriptionFeaturesRequest();

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    setFeaturesState((prevState) => ({
      ...prevState,
      features: response.features,
    }));
  };

  const closeModal = () => {
    setFeaturesState((prevState) => ({
      ...prevState,
      modalType: undefined,
      chosenFeature: undefined,
    }));
  };

  const addFeature = (): void => {
    setFeaturesState((prevState) => ({
      ...prevState,
      modalType: "ADD_FEATURE",
    }));
  };

  const createFeature = async (
    featureForm: FeatureFormModel
  ): Promise<void> => {
    const response = await createSubscriptionFeatureRequest(featureForm);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Feature created!");
    setFeaturesState((prevState) => ({
      features: [...prevState.features, response.feature],
      modalType: undefined,
      chosenFeature: undefined,
    }));
  };

  const editFeature = (feature: Feature): void => {
    setFeaturesState((prevState) => ({
      ...prevState,
      modalType: "EDIT_FEATURE",
      chosenFeature: feature,
    }));
  };

  const updateFeature = async (
    featureForm: FeatureFormModel
  ): Promise<void> => {
    const response = await updateSubscriptionFeatureRequest(
      featureForm,
      featuresState?.chosenFeature?.id
    );
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Feature updated!");
    setFeaturesState((prevState) => ({
      features: prevState.features.map((feature) =>
        feature.id === response.feature.id ? response.feature : feature
      ),
      modalType: undefined,
      chosenFeature: undefined,
    }));
  };

  const deleteSubscriptionFeature = async (id: number): Promise<void> => {
    const result = await swal({
      title: "Delete Subscription Feature?",
      text: "Are you sure you want to delete this feature?",
      buttons: [true, true],
      dangerMode: true,
    });
    if (!result) {
      return;
    }

    const response = await deleteSubscriptionFeatureRequest(id);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success(response.message);
    setFeaturesState((prevState) => ({
      ...prevState,
      features: prevState.features.filter((feature) => feature.id !== id),
    }));
  };

  useEffect(() => {
    getSubscriptionFeatures();
  }, []);

  return (
    <SubscriptionFeaturesStyles>
      <Header className="header">
        <Heading2>SUBSCRIPTION FEATURES</Heading2>
        <Button
          onClick={addFeature}
          buttonDesignType={buttonDesignType.PRIMARY}
        >
          Add Feature
        </Button>
      </Header>
      <Header className="tableHeader">
        <Paragraph className="listSubtitle">List Of Features</Paragraph>
        <div className="tableHead">
          {subscriptionFeaturesTableHead.map(({ title, className }, i) => (
            <div className={className} key={i}>
              <Paragraph className="head">{title}</Paragraph>
            </div>
          ))}
        </div>
      </Header>
      <Body className="body">
        {featuresState.features.map((feature) => (
          <FeaturesItem
            key={feature.id}
            {...feature}
            deleteAction={() => deleteSubscriptionFeature(feature.id)}
            editAction={() => editFeature(feature)}
          />
        ))}
      </Body>

      {featuresState.modalType && (
        <CentralModal className="modalCard" closeModal={closeModal}>
          <Card>
            <FeaturesForm
              updateFeature={updateFeature}
              createFeature={createFeature}
              modalType={featuresState.modalType}
              closeModal={closeModal}
              chosenFeature={featuresState.chosenFeature}
            />
          </Card>
        </CentralModal>
      )}
    </SubscriptionFeaturesStyles>
  );
};

export default SubscriptionFeatures;
