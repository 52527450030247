import { ApiResponse } from "apisauce";

// Api
import { api } from "../api";
import { getGeneralApiProblem } from "../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem,
} from "../general-api-problems/general-api-problems.types";

import {
  TrailerTypesResponse,
  TrailerTypesResponseDataType,
  TrailerTypeResponse,
  TrailerTypeResponseDataType,
  MessageResponse,
  MessageResponseType,
} from "../../models/api/responses";

import { TrailerTypeRequest } from "../../models/api/requests";

import { TrailerTypeFormModel } from "../../models/app/form";

export const getTrailerTypes = async (): Promise<
  TrailerTypesResponse | GeneralApiProblem
> => {
  const response: ApiResponse<TrailerTypesResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/trailer-type`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as TrailerTypesResponseDataType;
  return new TrailerTypesResponse(responseData);
};

export const createTrailerTypeRequest = async (
  trailerTypeForm: TrailerTypeFormModel
): Promise<TrailerTypeResponse | GeneralApiProblem> => {
  const requestPayload = new TrailerTypeRequest(trailerTypeForm);

  const response: ApiResponse<TrailerTypeResponseDataType, ExceptionPayload> =
    await api.apisauce.post("/trailer-type", requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TrailerTypeResponseDataType;

  return new TrailerTypeResponse(responseData);
};

export const updateTrailerTypeRequest = async (
  trailerTypeForm: TrailerTypeFormModel,
  id?: number
): Promise<TrailerTypeResponse | GeneralApiProblem> => {
  const requestPayload = new TrailerTypeRequest(trailerTypeForm);

  const response: ApiResponse<TrailerTypeResponseDataType, ExceptionPayload> =
    await api.apisauce.patch(`/trailer-type/${id}`, requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TrailerTypeResponseDataType;

  return new TrailerTypeResponse(responseData);
};

export const deleteTrailerTypeRequest = async (
  id: number
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/trailer-type/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;

  return new MessageResponse(responseData);
};
