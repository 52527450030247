// Dtos
import { BusinessDto } from "../dtos/Business.dto";

// Domain
import { Business } from "../../app/domain/Business";
// enums
import { ResponseStatusType } from "../../../enums/api";

export class BusinessResponse {
  business: Business;
  statusType: ResponseStatusType;
  constructor(responseData: BusinessDto) {
    this.business = new Business(responseData);
    this.statusType = ResponseStatusType.OK;
  }
}
