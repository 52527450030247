// styles
import styled from "styled-components";

export const PageHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 21px;
  border-bottom: 1px solid #c4c4c433;

  .backButton {
    padding: 9px 14px;
    display: flex;
    align-items: center;
    margin-right: 24px;

    .arrowContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      transform: rotate(-90deg);
      padding-top: 2.5px;
    }
  }
`;
