import styled from "styled-components";

const FooterStyles = styled.footer`
  padding: 24px;
  border-top: 1px solid #f1f1f5;
`;

type props = {
  children?: React.ReactNode;
  className?: string;
  style?: object;
};

const Footer = ({ children, style, className }: props) => (
  <FooterStyles style={{ ...style }} className={className}>
    {children}
  </FooterStyles>
);

export default Footer;
