import { Routes, Route } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import { useState } from "react";
import history from "./history";

import { CustomBrowserRouter } from "./Router/Custom-router";

import { PageWrapper } from "./components/UI";

import SideMenu from "./components/SideMenu/SideMenu";

import { generateRouterConfig } from "./config/routes.config";

import { Auth } from "./models/app/domain/Auth";

// Styles
import "react-toastify/dist/ReactToastify.css";

const AppRouter = () => {
  const [userAuth, setUserAuth] = useState<Auth>(new Auth());
  const { fullLayout, routes } = generateRouterConfig(userAuth?.accessToken);
  return (
    <CustomBrowserRouter history={history}>
      {!fullLayout && <SideMenu setUserAuth={setUserAuth} />}
      <Routes>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              <PageWrapper fullLayout={fullLayout}>
                <route.component setUserAuth={setUserAuth} />
              </PageWrapper>
            }
          />
        ))}
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
    </CustomBrowserRouter>
  );
};

export default AppRouter;
