// Dtos
import { AccounteDto } from "../dtos/Account.dto";

// Domain
import { Account } from "../../app/domain/Account";

// enums
import { ResponseStatusType } from "../../../enums/api";

export type AccountsResponseDataType = {
  accounts: AccounteDto[];
  statusType: ResponseStatusType;
};

export class AccountsResponse {
  accounts: Account[];
  statusType: ResponseStatusType;
  constructor(responseData: AccountsResponseDataType) {
    this.accounts = responseData.accounts.map(
      (account) => new Account(account)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
