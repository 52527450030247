import React from "react";

// Components
import { Button } from "../../../components/UI";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

type props = {
  setCurrentPage: (page: number) => void;
  totalPages: number;
  currentPage: number;
};

const Pagination = ({ totalPages, currentPage, setCurrentPage }: props) => {
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const nextPage = () => {
    if (currentPage === totalPages) return;

    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="pagination">
      <Button
        onClick={prevPage}
        buttonDesignType={buttonDesignType.PRIMARY}
        className={`${currentPage === 1 ? "prevDisabled" : ""}`}
      >
        Prev
      </Button>

      {pageNumbers.map((pageNumber) => (
        <Button
          key={pageNumber}
          onClick={() => setCurrentPage(pageNumber)}
          buttonDesignType={buttonDesignType.PRIMARY}
          className={`paginationItem ${
            currentPage === pageNumber ? "active" : null
          }`}
        >
          {pageNumber}
        </Button>
      ))}

      <Button
        onClick={nextPage}
        buttonDesignType={buttonDesignType.PRIMARY}
        className={`${currentPage === totalPages ? "nextDisabled" : ""}`}
      >
        Next
      </Button>
    </div>
  );
};

export default Pagination;
