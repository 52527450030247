import styled from "styled-components";

export const BusinessDetailsStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 14px 20px 20px;
    position: relative;
    z-index: 2;
  }

  .body {
    display: flex;

    padding: 0;

    .content {
      width: 100%;
      position: relative;

      .detail {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f1f1f5;
        margin-top: -1px;

        &:last-child {
          border-bottom: none;
        }

        .para {
          max-width: 70%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
        }
      }
    }
  }

  .footer {
    padding: 20px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
