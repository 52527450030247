import * as verificationStatus from "../../../constants/business.constants";

import { StatusPillStyles } from "./statusPill.styles";

interface props {
  style?: object;
  status: string;
  className?: string;
}

const StatusPill = ({ style, status, className }: props) => {
  return (
    <StatusPillStyles
      style={{ ...style }}
      status={status}
      className={className}
    >
      {status === verificationStatus.VERIFIED && "Verified"}
      {status === verificationStatus.PENDING && "Pending"}
      {status === verificationStatus.NOT_VERIFIED && "Not Verified"}
    </StatusPillStyles>
  );
};

export default StatusPill;
