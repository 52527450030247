// Dtos
import { ManufacturerDto } from "../dtos/Manufacturer.dto";

// Domain
import { Manufacturer } from "../../app/domain/Manufacturer";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TruckManufacturerResponseDataType = {
  truck_manufacturer: ManufacturerDto;
  statusType: ResponseStatusType;
};

export class TruckManufacturerResponse {
  manufacturer: Manufacturer;
  statusType: ResponseStatusType;
  constructor(responseData: TruckManufacturerResponseDataType) {
    this.manufacturer = new Manufacturer(responseData.truck_manufacturer);
    this.statusType = ResponseStatusType.OK;
  }
}
