import styled from "styled-components";

const CentralModalStyles = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: #00000040;
  z-index: 1000;
  top: 0;
  left: 0;

  .modalContainer {
    min-width: 330px;
    animation: showModal 0.4s ease-in-out;
    max-height: 90%;
    overflow-y: auto;
  }

  @keyframes showModal {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

type props = {
  children?: React.ReactNode;
  className?: string;
  closeModal?: () => void;
};

const CentralModal = ({ className, children, closeModal }: props) => (
  <CentralModalStyles onClick={closeModal}>
    <div
      className={`modalContainer ${className}`}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  </CentralModalStyles>
);

export default CentralModal;
