// Types
import { LabelProps } from "./all-typography.types";

// Styles
import { LabelStyles } from "./all-typography.styles";

const Label = ({ children, className, style }: LabelProps) => (
  <LabelStyles className={className} style={{ ...style }}>
    {children}
  </LabelStyles>
);

export default Label;
