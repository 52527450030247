// Dtos
import { AccounteDto } from "../dtos/Account.dto";

// Domain
import { Account } from "../../app/domain/Account";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type AccountResponseDataType = {
  account: AccounteDto;
  statusType: ResponseStatusType;
};

export class AccountResponse {
  account: Account;
  statusType: ResponseStatusType;
  constructor(responseData: AccountResponseDataType) {
    this.account = new Account(responseData.account);
    this.statusType = ResponseStatusType.OK;
  }
}
