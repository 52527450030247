// components
import { Button } from "../../../UI";
import { Paragraph } from "../../../typography";

// builders
import * as buttonDesignType from "../../../../builders/button-design.types";
// styles
import styled from "styled-components";

const FeaturesItemStyles = styled.div`
  padding: 8.5px 24px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e2e2ea;
  margin-top: -1px;
`;

interface props {
  id: number;
  title: string;
  editAction: () => void;
  deleteAction: () => void;
}

const FeaturesItem = ({ editAction, deleteAction, id, title }: props) => {
  return (
    <FeaturesItemStyles>
      <Paragraph className="id">{id}</Paragraph>
      <Paragraph className="title">{title}</Paragraph>
      <div className="actions">
        <Button onClick={editAction}>Edit</Button>
        <Button
          onClick={deleteAction}
          className="deleteButton"
          buttonDesignType={buttonDesignType.DANGER}
        >
          Delete
        </Button>
      </div>
    </FeaturesItemStyles>
  );
};

export default FeaturesItem;
