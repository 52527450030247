interface AccountFormType {
  email: string;
}

export class AccountFormModel {
  email: string;

  constructor(data?: AccountFormType) {
    this.email = data?.email || "";
  }
}
