interface ManufacturerFormType {
  imageUrl: string;
  manufacturer: string;
}

export class ManufacturerFormModel {
  imageUrl: string;
  manufacturer: string;

  constructor(data?: ManufacturerFormType) {
    this.imageUrl = data?.imageUrl || "";
    this.manufacturer = data?.manufacturer || "";
  }
}
