export const usersHeadBuilder = [
  {
    className: "ID",
    title: "ID",
  },
  {
    className: "email",
    title: "Email",
  },
  {
    className: "actions",
    title: "",
  },
];
