import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import {
  PageHeader,
  Card,
  Body,
  Header,
  Row,
  TextField,
  Button,
} from "../components/UI";
import { Heading2, Label, Paragraph } from "../components/typography";
import BranchItem from "../components/lists/branches/branches-item";
import Pagination from "../components/lists/pagination/pagination";

// Models
import { Branch } from "../models/app/domain/Branch";

// Requests
import { BranchSearchRequest } from "../models/api/requests";

// Calls
import {
  searchBranchesRequest,
  setFeaturedBranch,
  unsetFeaturedBranch,
} from "../api/calls/branches";

// Builders
import { branchHeadBuilder } from "../builders/branch.builder";
import * as buttonDesignType from "../builders/button-design.types";

// Enums
import { ResponseStatusType } from "../enums/api";

// Utils
import { errorHandler } from "../utils/error.utils";
import { filterEmptyKeys, isEmpty } from "../utils/global.utils";

// Styles
import { BranchesStyles } from "../pagesStyles/branches.styles";
import { PaginationStyles } from "../components/lists/pagination/pagination.styles";

type PageStateTypes = {
  branches: Branch[];
  currentPage: number;
  perPage: number;
  totalPages: number;
  filters: BranchSearchRequest;
};

const Branches = () => {
  const [pageState, setPageState] = useState<PageStateTypes>({
    branches: [],
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
    filters: {
      name: "",
      address: "",
    },
  });

  const handleNameChange = (event: any) => {
    setPageState((prevState) => ({
      ...prevState,
      filters: {
        name: event.target.value,
      },
    }));
  };

  const handleAddressChange = (event: any) => {
    setPageState((prevState) => ({
      ...prevState,
      filters: {
        address: event.target.value,
      },
    }));
  };

  const searchBranches = async (
    searchFilters?: BranchSearchRequest
  ): Promise<void> => {
    const response = await searchBranchesRequest(
      pageState.currentPage,
      pageState.perPage,
      filterEmptyKeys({
        name: !isEmpty(searchFilters)
          ? searchFilters?.name
          : pageState.filters.name,
        address: !isEmpty(searchFilters)
          ? searchFilters?.address
          : pageState.filters.address,
      })
    );

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    setPageState((prevState) => ({
      ...prevState,
      branches: response.branches,
      currentPage: response.pagination.page,
      totalPages: response.pagination.totalPages,
    }));
  };

  const resetSearchBranches = async (): Promise<void> => {
    if (pageState.currentPage === 1) {
      searchBranches({ name: "", address: "" });
    }

    setPageState((prevState) => ({
      ...prevState,
      filters: {
        name: "",
        address: "",
      },
      currentPage: 1,
    }));
  };

  const addFeaturedBranch = async (id: number): Promise<void> => {
    const response = await setFeaturedBranch(id);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    toast.success("Featured branch added!");
    searchBranches();
  };

  const removeFeaturedBranch = async (id: number): Promise<void> => {
    const response = await unsetFeaturedBranch(id);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Featured branch removed!");
    searchBranches();
  };

  useEffect(() => {
    searchBranches();
  }, [pageState.currentPage, pageState.perPage]);

  const setCurrentPage = (page: number) => {
    setPageState((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  return (
    <BranchesStyles>
      <PageHeader title="Branches" />
      <Card className="card">
        <Header className="header">
          <Heading2>ALL BRANCHES</Heading2>
        </Header>
        <Body>
          <form>
            <div className="nameWrapper">
              <Label>Name</Label>
              <TextField
                name="name"
                value={pageState.filters.name}
                onChange={handleNameChange}
                className="input"
                placeholder="Enter name"
              />
            </div>
            <div className="addressWrapper">
              <Label>Address</Label>
              <TextField
                name="address"
                value={pageState.filters.address}
                onChange={handleAddressChange}
                className="input"
                placeholder="Enter address"
              />
            </div>
            <div className="actionButtons">
              <Button
                onClick={() => {
                  searchBranches();
                }}
                className="actionButton"
                buttonDesignType={buttonDesignType.PRIMARY}
                disabled={
                  isEmpty(pageState.filters.name) &&
                  isEmpty(pageState.filters.address)
                }
              >
                Search
              </Button>
              <Button
                onClick={resetSearchBranches}
                className="actionButton"
                buttonDesignType={buttonDesignType.PRIMARY}
              >
                Reset
              </Button>
            </div>
          </form>
        </Body>
        <Body className="tableHead">
          {branchHeadBuilder.map(({ title, className }, i) => (
            <Paragraph className={`tableHeadTitle ${className}`} key={i}>
              {title}
            </Paragraph>
          ))}
        </Body>
      </Card>

      <Card className="branches">
        <Body className="list">
          {pageState.branches.map((branch) => (
            <Row key={branch.id} style={{ cursor: "pointer" }}>
              <BranchItem
                branch={branch}
                addFeaturedBranch={() => addFeaturedBranch(branch.id)}
                removeFeaturedBranch={() => removeFeaturedBranch(branch.id)}
              />
            </Row>
          ))}
        </Body>
        <PaginationStyles>
          <Pagination
            totalPages={pageState.totalPages}
            currentPage={pageState.currentPage}
            setCurrentPage={setCurrentPage}
          />
        </PaginationStyles>
      </Card>
    </BranchesStyles>
  );
};

export default Branches;
