type LoginFormType = {
  email: string;
  password: string;
};

export class LoginFormModel {
  email: string;
  password: string;

  constructor(data?: LoginFormType) {
    this.email = data?.email || "milos.danilovic+admin1@quantox.com";
    this.password = data?.password || "milos.danilovic";
  }
}
