// components
import { Paragraph } from "../../../typography";
import { Button, ExpandingDescription } from "../../../UI";
// builders
import * as buttonDesignType from "../../../../builders/button-design.types";

// Domain Models
import { Price } from "../../../../models/app/domain/Price";
import { SubscriptionPlanFeature } from "../../../../models/app/domain/SubscriptionPlanFeature";

// icons
import { Trash } from "../../../../SVGS";
// styles
import { SubscriptionPlanItemStyles } from "./subscription-plan-item-styles";

interface props {
  name: string;
  description: string;
  price: Price;
  allowedNumberOfBranches: number;
  active: boolean;
  recommended: boolean;
  subscriptionPlanFeatures: SubscriptionPlanFeature[];
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
}

const SubscriptionPlanItem = ({
  name,
  description,
  price,
  allowedNumberOfBranches,
  active,
  editAction,
  deleteAction,
  activateAction,
  recommended,
  subscriptionPlanFeatures,
}: props) => {
  return (
    <SubscriptionPlanItemStyles>
      <div className="topContent">
        <div className="featureContainer">
          {subscriptionPlanFeatures.map((feature) => (
            <Paragraph
              key={feature.subscriptionPlanFeature.id}
              className="serviceTypeName"
              style={{ color: feature.supported ? "#74bde9" : "black" }}
            >
              {feature.subscriptionPlanFeature.title}
            </Paragraph>
          ))}
        </div>

        <div className="buttonsContainer">
          {!active && (
            <Button
              onClick={activateAction}
              className="buttons"
              style={{ marginRight: 8 }}
            >
              Activate
            </Button>
          )}
          <Button onClick={editAction} className="buttons">
            Edit Plan
          </Button>
          <Button
            onClick={deleteAction}
            buttonDesignType={buttonDesignType.DANGER}
            className="buttons"
            style={{ marginLeft: 8 }}
          >
            <Trash />
          </Button>
        </div>
      </div>
      <h3 className="shopTitle">{name}</h3>
      <div className="priceAvailability">
        <Paragraph className="price text">$ {price.unitAmount}</Paragraph>

        {recommended && <Paragraph className="text">Recommended</Paragraph>}
      </div>
      <div className="priceAvailability">
        <Paragraph className="text">
          Active branches: {allowedNumberOfBranches}
        </Paragraph>
      </div>
      <div className="priceAvailability">
        <Paragraph className="price text">
          {active ? "Active" : "Inactive"}
        </Paragraph>
      </div>
      <ExpandingDescription title="description" text={description} />
    </SubscriptionPlanItemStyles>
  );
};

export default SubscriptionPlanItem;
