// styles
import styled from "styled-components";

export const PaginationStyles = styled.div`
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
  }

  .paginationItem {
    background: #fff;
    color: #999;
    height: 3%;
    width: 3%;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
  }

  .paginationItem.active {
    border: 1px solid #202650;
    color: #202650;
    pointer-events: none;
  }

  .prevDisabled,
  .nextDisabled {
    pointer-events: none;
    box-shadow: none;
    color: #999;
  }
`;
