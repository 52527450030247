import { SubscriptionPlanFeatureForm } from "../../app/form";

export class SubscriptionPlanFeatureRequest {
  subscription_feature_id: number;
  supported: boolean;

  constructor(feature: SubscriptionPlanFeatureForm) {
    this.subscription_feature_id = feature.subscriptionFeatureId;
    this.supported = feature.supported;
  }
}
