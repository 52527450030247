import { useState } from "react";
import styled from "styled-components";

// Components
import { PageHeader, Tab, Card } from "../components/UI";

// Containers
import SubscriptionFeatures from "../containers/payment/subscription-features/Subscription-features";
import SubscriptionPlans from "../containers/payment/subscription-plans/Subscription-plans";

// Builders
import { tabsBuilder } from "../builders/payment.builder";

// Constants
import * as tabValues from "../constants/payment.constants";

const PaymentSystemStyles = styled.section`
  min-height: calc(100vh - 42px);

  .contentContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 24px;

    .tabs {
      width: 32.6%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    }

    .cardContainer {
      width: 63.8%;
    }
  }
`;

const PaymentSystem = () => {
  const [chosenTab, setChosenTab] = useState(tabValues.SUBSCRIPTION_PLANS);
  return (
    <PaymentSystemStyles>
      <PageHeader title="Payment System" />
      <div className="contentContainer">
        <div className="tabs">
          {tabsBuilder.map((tab, i) => (
            <Tab
              {...tab}
              isActive={tab.value === chosenTab}
              onClick={setChosenTab}
              key={i}
            />
          ))}
        </div>
        <div className="cardContainer">
          <Card>
            {chosenTab === tabValues.SUBSCRIPTION_PLANS && (
              <SubscriptionPlans />
            )}
            {chosenTab === tabValues.SUBSCRIPTION_FEATURES && (
              <SubscriptionFeatures />
            )}
          </Card>
        </div>
      </div>
    </PaymentSystemStyles>
  );
};

export default PaymentSystem;
