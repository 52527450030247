// Dtos
import { PriceDto } from "../../api/dtos/Price.dto";

// Form Model
// import { FeatureFormModel } from "../form/feature.form";

export class Price {
  id: number;
  unitAmount: number;

  constructor(entity: PriceDto) {
    this.id = entity.id;
    this.unitAmount = entity.unit_amount / 100;
  }
}
