interface featureParams {
  title: string;
}
export const featureValidate = ({ title }: featureParams): any => {
  const errors: any = {};
  if (!title) {
    errors.title = "* Required";
  }

  return errors;
};
