import { useFormik } from "formik";
import { useEffect, useState } from "react";
// Components
import {
  Body,
  Header,
  TextField,
  Button,
  Footer,
  Textarea,
  Row,
  Checkbox,
} from "../../../../components/UI";
import { Heading2, Label, Paragraph } from "../../../../components/typography";

// Icons
import { Close } from "../../../../SVGS";

// Builders
import * as buttonDesignType from "../../../../builders/button-design.types";

// Form Model
import {
  PlanFormModel,
  SubscriptionPlanFeatureForm,
} from "../../../../models/app/form";

// Domain Model
import { SubscriptionPlan } from "../../../../models/app/domain/SubscriptionPlan";

// Validation
import { planValidate } from "../../../validations/plan.validation";

// Calls
import { getSubscriptionFeaturesRequest } from "../../../../api/calls/payment";

// enums
import { ResponseStatusType } from "../../../../enums/api";

// Utils
import { errorHandler } from "../../../../utils/error.utils";

// Styles
import { PlanFormStyles } from "./plan-form.styles";

type props = {
  createFeature: (newTrailerType: PlanFormModel) => void;
  updateFeature: (updatedTrailerType: PlanFormModel) => void;
  modalType: string;
  closeModal: () => void;
  chosenPlan?: SubscriptionPlan;
};

const PlanForm = ({
  createFeature,
  updateFeature,
  modalType,
  closeModal,
  chosenPlan,
}: props) => {
  const [initialState, setInitialState] = useState<PlanFormModel>(
    new PlanFormModel()
  );
  const formSubmitHandler = (values: PlanFormModel): void => {
    if (modalType === "ADD_PLAN") {
      createFeature(values);
      return;
    }
    if (modalType === "EDIT_PLAN") {
      updateFeature(values);
      return;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: formSubmitHandler,
    validate: planValidate,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleBlur,
  } = formik;

  const getSubscriptionFeatures = async () => {
    const response = await getSubscriptionFeaturesRequest();

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    if (modalType === "ADD_PLAN") {
      setFieldValue(
        "subscriptionPlanFeatures",
        response.features.map(
          (feature) =>
            new SubscriptionPlanFeatureForm({
              subscriptionFeatureId: feature.id,
              title: feature.title,
              supported: false,
            })
        )
      );
      return;
    }

    if (modalType === "EDIT_PLAN" && chosenPlan) {
      setFieldValue(
        "subscriptionPlanFeatures",
        response.features.map((feature) => {
          const findPlanFeature = chosenPlan.subscriptionPlanFeatures.find(
            (planFeature) =>
              planFeature.subscriptionPlanFeature.id === feature.id
          );

          if (findPlanFeature) {
            return new SubscriptionPlanFeatureForm({
              subscriptionFeatureId: findPlanFeature.subscriptionPlanFeature.id,
              title: feature.title,
              supported: findPlanFeature.supported,
            });
          }
          return new SubscriptionPlanFeatureForm({
            subscriptionFeatureId: feature.id,
            title: feature.title,
            supported: false,
          });
        })
      );
    }
  };

  useEffect(() => {
    if (chosenPlan) {
      setInitialState(chosenPlan.generateForm());
    }
    getSubscriptionFeatures();
  }, []);

  const generateTitle = () => {
    switch (modalType) {
      case "ADD_PLAN":
        return "ADD SUBSCRIPTION PLAN";
      case "EDIT_PLAN":
        return "EDIT SUBSCRIPTION PLAN";
      default:
        return "";
    }
  };

  const generateButtonTitle = () => {
    switch (modalType) {
      case "ADD_PLAN":
        return "Create Subscription Plan";
      case "EDIT_PLAN":
        return "Save Changes";
      default:
        return "";
    }
  };

  const handleFeatureChange = (id: number): void => {
    setFieldValue(
      "subscriptionPlanFeatures",
      values.subscriptionPlanFeatures.map((feature) =>
        feature.subscriptionFeatureId === id
          ? new SubscriptionPlanFeatureForm({
              subscriptionFeatureId: id,
              title: feature.title,
              supported: !feature.supported,
            })
          : feature
      )
    );
  };

  return (
    <PlanFormStyles>
      <Header className="formHeader">
        <Heading2>{generateTitle()}</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <form autoComplete="new-password" onSubmit={handleSubmit}>
        <Body>
          <div className="formBody">
            <div className="container">
              <div className="inputContainer">
                <Label>Subscription Plan Name</Label>
                <TextField
                  name="name"
                  value={values.name}
                  touched={touched.name}
                  error={errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="input"
                  placeholder="Enter a feature name"
                />
              </div>
              {modalType === "ADD_PLAN" && (
                <div className="inputContainer">
                  <Label>Subscription Plan Price</Label>
                  <TextField
                    type="number"
                    name="price"
                    value={values.price}
                    touched={touched.price}
                    error={errors.price}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="input"
                    placeholder="Enter a feature name"
                  />
                </div>
              )}

              <div className="inputContainer">
                <Label>Allowed Number Of Branches</Label>
                <TextField
                  type="number"
                  name="allowedNumberOfBranches"
                  value={values.allowedNumberOfBranches}
                  touched={touched.allowedNumberOfBranches}
                  error={errors.allowedNumberOfBranches}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="input"
                  placeholder="Enter a feature name"
                />
              </div>
            </div>
            <div className="container">
              <div className="inputContainer">
                <Label>Description</Label>
                <Textarea
                  name="description"
                  value={values.description}
                  touched={touched.description}
                  error={errors.description}
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  className="description"
                  placeholder="Enter a feature name"
                />
              </div>
              <Checkbox
                value={values.givesPremiumAccess}
                handleChange={() =>
                  setFieldValue(
                    "givesPremiumAccess",
                    !values.givesPremiumAccess
                  )
                }
                label="Gives premium access"
              />
            </div>
          </div>
          <div>
            <Label>Features</Label>
            <div className="permissionsList">
              {values.subscriptionPlanFeatures.map((planFeature, i) => (
                <Row className="row" key={i}>
                  <Checkbox
                    value={planFeature.supported}
                    handleChange={() =>
                      handleFeatureChange(planFeature.subscriptionFeatureId)
                    }
                  />
                  <div className="textContainer">
                    <Paragraph className="permissionName">
                      {planFeature.title}
                    </Paragraph>
                  </div>
                </Row>
              ))}
            </div>
          </div>
        </Body>
        <Footer>
          <div className="buttonContainer">
            <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
              {generateButtonTitle()}
            </Button>
          </div>
        </Footer>
      </form>
    </PlanFormStyles>
  );
};

export default PlanForm;
