import styled from "styled-components";

export const SubscriptionPlanItemStyles = styled.div`
  border-top: 1px solid #f1f1f5;
  padding: 12px 24px 0 24px;

  &:first-child {
    border-top: none;
  }

  .topContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .featureContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .serviceTypeName {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #74bde9;
        margin-right: 16px;
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      .buttons {
        height: 41px;
      }

      .buttons:hover path {
        fill: #ffffff;
      }
    }
  }

  .shopTitle {
    margin: 10px 0 15px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .priceAvailability {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #44444f;
    }

    .price {
      margin-right: 24px;
    }
  }
`;
