import styled from "styled-components";

interface CardStylesProps {
  rounded?: boolean;
}

export const CardStyles = styled.div<CardStylesProps>`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: ${(rounded) => rounded && "3px"};
`;
