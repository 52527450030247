// components
import { Button } from "../../UI";
import { Heading1 } from "../../typography";
// icons
import { DropDownArrow } from "../../../SVGS";

// history
import history from "../../../history";

// Types
import { PageHeaderProps } from "./page-header.types";

// Styles
import { PageHeaderStyles } from "./PageHeader.styes";

const PageHeader = ({ title }: PageHeaderProps) => {
  return (
    <PageHeaderStyles>
      <Button onClick={() => history.back()} className="backButton">
        <div className="arrowContainer">
          <DropDownArrow />
        </div>
        Back
      </Button>
      <Heading1>{title}</Heading1>
    </PageHeaderStyles>
  );
};

export default PageHeader;
