// Components
import { Paragraph } from "../../typography";
import { Button } from "../../UI";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Models
import { Manufacturer } from "../../../models/app/domain/Manufacturer";

// Utils
import { isEmpty } from "../../../utils/global.utils";

// Styles
import { ManufacturerItemStyles } from "./manufacturer-item.styles";

type props = {
  onClick?: () => void;
  editAction?: () => void;
  deleteAction?: () => void;
  manufacturer: Manufacturer;
};

const ManufacturerItem = ({
  onClick,
  editAction,
  deleteAction,
  manufacturer,
}: props) => {
  return (
    <ManufacturerItemStyles onClick={onClick}>
      <Paragraph className="ID Id">{manufacturer.id}</Paragraph>
      <Paragraph className="name userText">
        {manufacturer.manufacturer}
      </Paragraph>
      <Paragraph className="type serviceTypeText">
        {manufacturer.type}
      </Paragraph>
      {isEmpty(manufacturer?.image?.url) ? (
        <Paragraph className="image date">/</Paragraph>
      ) : (
        <div className="image">
          <img
            src={manufacturer?.image?.url}
            style={{ maxWidth: "80px" }}
            alt={`manufacturer${manufacturer.id}`}
          />
        </div>
      )}

      <div className="actions">
        <Button
          className="actionButtons"
          style={{ marginRight: 12 }}
          buttonDesignType={buttonDesignType.SECONDARY}
          onClick={editAction}
        >
          Edit
        </Button>
        <Button
          onClick={deleteAction}
          className="actionButtons"
          buttonDesignType={buttonDesignType.DANGER}
        >
          Delete
        </Button>
      </div>
    </ManufacturerItemStyles>
  );
};

export default ManufacturerItem;
