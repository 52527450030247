interface planParams {
  name: string;
  price: number;
  description: string;
  allowedNumberOfBranches: number;
}
export const planValidate = ({
  name,
  description,
  allowedNumberOfBranches,
}: planParams): any => {
  const errors: any = {};
  if (!name) {
    errors.name = "* Required";
  }

  if (!description) {
    errors.description = "* Required";
  }

  if (!allowedNumberOfBranches) {
    errors.allowedNumberOfBranches = "* Required";
  }

  return errors;
};
