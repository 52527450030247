// Components
import { Paragraph } from "../../typography";
import { Button } from "../../UI";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Models
import { TrailerType } from "../../../models/app/domain/TrailerType";

// Styles
import { TrailerTypeItemStyles } from "./trailer-type-item.styles";

type props = {
  onClick?: () => void;
  editAction?: () => void;
  declineAction?: () => void;
  trailerType: TrailerType;
};

const TrailerTypeItem = ({
  onClick,
  editAction,
  declineAction,
  trailerType,
}: props) => {
  return (
    <TrailerTypeItemStyles onClick={onClick}>
      <Paragraph className="ID Id">{trailerType.id}</Paragraph>
      <Paragraph className="name userText">{trailerType.name}</Paragraph>

      <div className="actions">
        <Button
          className="actionButtons"
          style={{ marginRight: 12 }}
          buttonDesignType={buttonDesignType.SECONDARY}
          onClick={editAction}
        >
          Edit
        </Button>
        <Button
          onClick={declineAction}
          className="actionButtons"
          buttonDesignType={buttonDesignType.DANGER}
        >
          Delete
        </Button>
      </div>
    </TrailerTypeItemStyles>
  );
};

export default TrailerTypeItem;
