interface trailerTypeParams {
  name: string;
}
export const trailerTypeValidate = ({ name }: trailerTypeParams): any => {
  const errors: any = {};
  if (!name) {
    errors.name = "* Required";
  }

  return errors;
};
