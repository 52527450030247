import { ApiResponse } from "apisauce";

// Dtos
import { BusinessDto } from "../../models/api/dtos/Business.dto";

// Api
import { api } from "../api";
import { getGeneralApiProblem } from "../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem,
} from "../general-api-problems/general-api-problems.types";

import {
  BusinessResponse,
  BusinessesResponse,
  BusinessesResponseDataType,
} from "../../models/api/responses";

export const getBusinessesRequest = async (
  page: number,
  perPage: number
): Promise<BusinessesResponse | GeneralApiProblem> => {
  const response: ApiResponse<BusinessesResponseDataType, ExceptionPayload> =
    await api.apisauce.get(
      `/businesses?pagination[per_page]=${perPage}&pagination[page]=${page}`
    );

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as BusinessesResponseDataType;
  return new BusinessesResponse(responseData);
};

export const setBusinessReview = async (
  status: string,
  id?: number
): Promise<BusinessResponse | GeneralApiProblem> => {
  const response: ApiResponse<BusinessDto, ExceptionPayload> =
    await api.apisauce.post(`/businesses/${id}/review`, {
      verification_status: status,
    });

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as BusinessDto;
  return new BusinessResponse(responseData);
};
