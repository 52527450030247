// Domain Models
import { Location } from "./Location";

// Dtos
import { BranchDto } from "../../api/dtos/Branch.dto";

export class Branch {
  id: number;
  name: string;
  description: string | null;
  isFeatured: boolean;
  location: Location | null;

  constructor(entity: BranchDto) {
    this.id = entity.id;
    this.name = entity.name;
    this.description = entity.description;
    this.isFeatured = entity.is_featured;
    this.location = entity.location;
  }
}
