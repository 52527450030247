// DTOS
import { AuthDTO } from "../dtos/Auth.dto";
// DOMAIN Models
import { Auth } from "../../app/domain/Auth";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type LoginResponseDataType = {
  auth: AuthDTO;
  statusType: ResponseStatusType;
};

export class LoginResponse {
  auth: Auth;
  statusType: ResponseStatusType;

  constructor(responseData: LoginResponseDataType) {
    this.auth = new Auth(responseData.auth);
    this.statusType = ResponseStatusType.OK;
  }
}
