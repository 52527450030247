// constants
import * as verificationStatus from "../../../constants/business.constants";
// styles
import styled from "styled-components";

interface status {
  status: string;
}

export const StatusPillStyles = styled.div`
  padding: 2px 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  max-width: 120px;
  width: 100%;
  ${({ status }: status) => {
    switch (status) {
      case verificationStatus.VERIFIED:
        return `
        min-width: 80px;
        background: #E3FCEF;
        color: #006644;
        border: 1px solid #ABF5D1;
      `;

      case verificationStatus.PENDING:
        return `
        min-width: 62px;
        background: #E3E3E3;
        color: #535353;
        border: 1px solid #9E9E9E;
      `;

      case verificationStatus.NOT_VERIFIED:
        return `
        min-width: 69px;
        background: #FFEBE6;
        color: #B92500;
        border: 1px solid #FFBDAD;
      `;

      default:
        return `
        min-width: 80px;
        background: #E3FCEF;
        color: #006644;
        border: 1px solid #ABF5D1;
      `;
    }
  }};
`;
