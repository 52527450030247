interface SubscriptionPlanFeatureFormType {
  subscriptionFeatureId: number;
  title: string;
  supported: boolean;
}

export class SubscriptionPlanFeatureForm {
  subscriptionFeatureId: number;
  title: string;
  supported: boolean;

  constructor(data?: SubscriptionPlanFeatureFormType) {
    this.subscriptionFeatureId = data?.subscriptionFeatureId || 0;
    this.title = data?.title || "";
    this.supported = data?.supported || false;
  }
}
