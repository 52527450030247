// Dtos
import { AccounteDto } from "../../api/dtos/Account.dto";

// Form Model
import { AccountFormModel } from "../form";

export class Account {
  id: number;
  email: string;

  constructor(entity: AccounteDto) {
    this.id = entity.id;
    this.email = entity.email;
  }

  generateForm(): AccountFormModel {
    return {
      email: this.email || "",
    };
  }
}
