// styles
import { PageWrapperStyles } from "./page-wrapper.styles";

interface props {
  children?: React.ReactNode;
  fullLayout: boolean;
}

const PageWrapper = ({ children, fullLayout }: props) => {
  return (
    <PageWrapperStyles fullLayout={fullLayout}>{children}</PageWrapperStyles>
  );
};

export default PageWrapper;
