export const businessesHeadBuilder = [
  {
    className: "ID",
    title: "ID",
  },
  {
    className: "name",
    title: "Name",
  },
  {
    className: "status",
    title: "Status",
  },
  {
    className: "proofDocument",
    title: "Proof Document",
  },
  {
    className: "taxId",
    title: "Tax Identification",
  },
  {
    className: "actions",
    title: "",
  },
];
