// Components
import { Paragraph } from "../../typography";
import { Button } from "../../UI";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Models
import { Branch } from "../../../models/app/domain/Branch";

// Styles
import { BranchesItemStyles } from "./branches-item.styles";

type props = {
  addFeaturedBranch?: () => void;
  removeFeaturedBranch?: () => void;
  branch: Branch;
};

const BranchItem = ({
  addFeaturedBranch,
  removeFeaturedBranch,
  branch,
}: props) => {
  return (
    <BranchesItemStyles>
      <Paragraph className="ID Id">{branch.id}</Paragraph>
      <Paragraph className="name">{branch.name}</Paragraph>
      <Paragraph className="address">{branch.location?.address}</Paragraph>

      <div className="actions">
        {branch.isFeatured ? (
          <Button
            onClick={removeFeaturedBranch}
            className="actionButtons"
            buttonDesignType={buttonDesignType.DANGER}
          >
            Remove
          </Button>
        ) : (
          <Button
            onClick={addFeaturedBranch}
            className="actionButtons"
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            Add
          </Button>
        )}
      </div>
    </BranchesItemStyles>
  );
};

export default BranchItem;
