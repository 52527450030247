import { FeatureFormModel } from "../../app/form";

export class CreateFeatureRequest {
  title: string;
  assign_to_all_plans: boolean;

  constructor(featureForm: FeatureFormModel) {
    this.title = featureForm.title;
    this.assign_to_all_plans = true;
  }
}
