// styles
import { TabStyles } from "./tab.styles";

interface props {
  tabName: string;
  value: string;
  isActive: boolean;
  onClick: (value: string) => void;
}

const Tab = ({ tabName, value, isActive, onClick }: props) => {
  return (
    <TabStyles onClick={() => onClick(value)} isActive={isActive}>
      {/* {icon} */}
      <p className="tabName">{tabName}</p>
    </TabStyles>
  );
};

export default Tab;
