import { ApiResponse } from "apisauce";

// Api
import { api } from "../api";
import { getGeneralApiProblem } from "../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem,
} from "../general-api-problems/general-api-problems.types";

// UI Models
import { LoginFormModel } from "../../models/app/form";

// Request Models
import { LoginRequest } from "../../models/api/requests";

// Response Models
import {
  LoginResponse,
  LoginResponseDataType,
} from "../../models/api/responses/login.response";

/**
 * Login Request
 * @param formValues - LoginUI
 * @returns LoginResponse or GeneralApiProblem
 */
export const loginRequest = async (
  loginForm: LoginFormModel
): Promise<LoginResponse | GeneralApiProblem> => {
  const requestPayload = new LoginRequest(loginForm);
  const response: ApiResponse<LoginResponseDataType, ExceptionPayload> =
    await api.apisauce.post("/auth/login", requestPayload);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as LoginResponseDataType;
  return new LoginResponse(responseData);
};
