import { Link } from "react-router-dom";
// styles
import { NavLinkStyles } from "./nav-link.styles";

type props = {
  isActive?: boolean;
  path?: string;
  name: string;
  Icon?: string;
  onClick?: () => void;
};

const NavLink = ({ isActive = false, path, Icon, name, onClick }: props) => {
  return path ? (
    <Link style={{ textDecoration: "none" }} to={path}>
      <NavLinkStyles isActive={isActive}>
        {Icon && <Icon />}
        <p className="linkName">{name}</p>
      </NavLinkStyles>
    </Link>
  ) : (
    <NavLinkStyles onClick={onClick && onClick} isActive={isActive}>
      {Icon && <Icon />}
      <p className="linkName">{name}</p>
    </NavLinkStyles>
  );
};

export default NavLink;
