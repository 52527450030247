interface accountParams {
  email: string;
}
export const accountValidate = ({ email }: accountParams): any => {
  const errors: any = {};
  if (!email) {
    errors.email = "* Required";
  }

  return errors;
};
