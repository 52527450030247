// Components
import { Slider } from "../components/UI";
import { Heading1 } from "../components/typography";

// Containers
import LoginForm from "../containers/forms/login/login.form";

// Icons
import { TruckFixLogo } from "../SVGS";

// Styles
import { LoginStyles } from "../pagesStyles/login.styles";

const images = [
  "https://erbgroup.com/wp-content/uploads/2018/06/Blog-photo-baden-shop.jpg",
  "https://cdn.dealerspike.com/imglib/v1/800x600/imglib/Assets/Blog/a1/23/a123fdbc-3a93-4a86-bc8e-fed83c7c3a58.jpg",
  "https://www.tindolford.com/static/dealer-14653/903629.jpg",
];

interface props {
  setUserAuth: () => void;
}

const Login = ({ setUserAuth }: props) => {
  return (
    <LoginStyles>
      <div className="formContainer">
        <TruckFixLogo className="logo" />
        <Heading1 style={{ margin: "30px 0" }}>
          Log In to TruckFix System Portal
        </Heading1>
        <LoginForm setUserAuth={setUserAuth} />
      </div>
      <div className="sliderContainer">
        <Slider images={images} />
      </div>
    </LoginStyles>
  );
};

export default Login;
