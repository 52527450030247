export const branchHeadBuilder = [
  {
    className: "ID",
    title: "ID",
  },
  {
    className: "name",
    title: "Name",
  },
  {
    className: "address",
    title: "Address",
  },
  {
    className: "actions",
    title: "Featured",
  },
];
