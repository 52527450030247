import { LoginFormModel } from "../../app/form/login.form.model";

export class LoginRequest {
  email: string;
  password: string;

  constructor(loginForm: LoginFormModel) {
    this.email = loginForm.email;
    this.password = loginForm.password;
  }
}
