import { ApiResponse } from "apisauce";

// Api
import { api } from "../api";
import { getGeneralApiProblem } from "../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem,
} from "../general-api-problems/general-api-problems.types";

import {
  TrailerManufacturerResponse,
  TrailerManufacturerResponseDataType,
  TrailerManufacturersResponse,
  TrailerManufacturersResponseDataType,
  MessageResponse,
  MessageResponseType,
} from "../../models/api/responses";

import { ManufacturerRequest } from "../../models/api/requests";

import { ManufacturerFormModel } from "../../models/app/form";

/**
 * Get Trucks manufacturers request
 * @returns TrucksManufacturersResponse or GeneralApiProblem
 */

export const getTrailerManufacturers = async (): Promise<
  TrailerManufacturersResponse | GeneralApiProblem
> => {
  const response: ApiResponse<
    TrailerManufacturersResponseDataType,
    ExceptionPayload
  > = await api.apisauce.get(`/trailer-manufacturer`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as TrailerManufacturersResponseDataType;
  return new TrailerManufacturersResponse(responseData);
};

/**
 * Create truck manufacturer request
 * @param manufacturerForm - ManufacturerFormModel
 * @returns TrailerManufacturerResponse or GeneralApiProblem
 */
export const createTrailerManufacturerRequest = async (
  manufacturerForm: ManufacturerFormModel
): Promise<TrailerManufacturerResponse | GeneralApiProblem> => {
  const requestPayload = new ManufacturerRequest(manufacturerForm);

  const response: ApiResponse<
    TrailerManufacturerResponseDataType,
    ExceptionPayload
  > = await api.apisauce.post("/trailer-manufacturer", requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TrailerManufacturerResponseDataType;

  return new TrailerManufacturerResponse(responseData);
};

/**
 * Update truck manufacturer request
 * @param manufacturerForm - ManufacturerFormModel
 * @param id - string
 * @returns TrailerManufacturerResponse or GeneralApiProblem
 */
export const updateTrailerManufacturerRequest = async (
  manufacturerForm: ManufacturerFormModel,
  id?: number
): Promise<TrailerManufacturerResponse | GeneralApiProblem> => {
  const requestPayload = new ManufacturerRequest(manufacturerForm);

  const response: ApiResponse<
    TrailerManufacturerResponseDataType,
    ExceptionPayload
  > = await api.apisauce.patch(`/trailer-manufacturer/${id}`, requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TrailerManufacturerResponseDataType;

  return new TrailerManufacturerResponse(responseData);
};

/**
 * Delete truck manufacturer request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deleteManufacturerRequest = async (
  id: number
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/trailer-manufacturer/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;

  return new MessageResponse(responseData);
};
