import { useFormik } from "formik";
import { useEffect, useState } from "react";
// Components
import {
  Body,
  Header,
  TextField,
  Button,
  Footer,
} from "../../../../components/UI";
import { Heading2, Label } from "../../../../components/typography";

// Icons
import { Close } from "../../../../SVGS";

// Builders
import * as buttonDesignType from "../../../../builders/button-design.types";

// Form Model
import { FeatureFormModel } from "../../../../models/app/form";

// Domain Model
import { Feature } from "../../../../models/app/domain/Feature";

// Validation
import { featureValidate } from "../../../validations/feature.validations";

// Styles
import { FeaturesFormStyles } from "./features-form.styles";

type props = {
  createFeature: (newTrailerType: FeatureFormModel) => void;
  updateFeature: (updatedTrailerType: FeatureFormModel) => void;
  modalType: string;
  closeModal: () => void;
  chosenFeature?: Feature;
};

const FeaturesForm = ({
  createFeature,
  updateFeature,
  modalType,
  closeModal,
  chosenFeature,
}: props) => {
  const [initialState, setInitialState] = useState<FeatureFormModel>(
    new FeatureFormModel()
  );
  const formSubmitHandler = (values: FeatureFormModel): void => {
    if (modalType === "ADD_FEATURE") {
      createFeature(values);
    }
    if (modalType === "EDIT_FEATURE") {
      updateFeature(values);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: formSubmitHandler,
    validate: featureValidate,
  });

  useEffect(() => {
    if (chosenFeature) {
      setInitialState(chosenFeature.generateForm());
    }
  }, []);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  const generateTitle = () => {
    switch (modalType) {
      case "ADD_FEATURE":
        return "ADD SUBSCRIPTION FEATURE";
      case "EDIT_FEATURE":
        return "EDIT SUBSCRIPTION FEATURE";
      default:
        return "";
    }
  };

  const generateButtonTitle = () => {
    switch (modalType) {
      case "ADD_FEATURE":
        return "Create Subscription Feature";
      case "EDIT_FEATURE":
        return "Save Changes";
      default:
        return "";
    }
  };

  return (
    <FeaturesFormStyles>
      <Header className="formHeader">
        <Heading2>{generateTitle()}</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <form autoComplete="new-password" onSubmit={handleSubmit}>
        <Body>
          <div className="inputContainer">
            <Label>Subscription Feature Name</Label>
            <TextField
              name="title"
              value={values.title}
              touched={touched.title}
              error={errors.title}
              onBlur={handleBlur}
              onChange={handleChange}
              className="input"
              placeholder="Enter a feature name"
            />
          </div>
        </Body>
        <Footer>
          <div className="buttonContainer">
            <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
              {generateButtonTitle()}
            </Button>
          </div>
        </Footer>
      </form>
    </FeaturesFormStyles>
  );
};

export default FeaturesForm;
