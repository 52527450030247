import { useState } from "react";
import { toast } from "react-toastify";
import MoonLoader from "react-spinners/MoonLoader";

// Components
import Button from "../Button/Button";

// Icons
import { FileIcon, Trash } from "../../../SVGS";

// Builders
import * as buttonTypes from "../../../builders/button-design.types";

// Calls
import { fileUpload } from "../../../api/calls/file-upload.call";

// Enums
import { ResponseStatusType } from "../../../enums/api";

// Styles
import { FileUplaodStyles } from "./fileUpload.styles";

interface props {
  placeholder?: string;
  name: string;
  id: string;
  value: string;
  touched?: boolean;
  error?: string;
  setFieldValue: (name: string, value: string | null) => void;
}

const FileUpload = ({
  placeholder = "Upload file",
  name,
  id,
  value,
  touched,
  error,
  setFieldValue,
}: props) => {
  const [isLoading, setIsLoading] = useState(false);

  const openFileUpload = (): void => {
    const input = document.getElementById(id);
    if (input) input.click();
  };

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const input = document.getElementById(id) as HTMLInputElement;
    const target = e.target as HTMLInputElement;
    const file: File | null = (target.files as FileList)[0];
    setIsLoading(true);
    const response = await fileUpload(file);
    if (response.statusType !== ResponseStatusType.OK) {
      // toast.error(fileUploadResponse.error.message);
      setIsLoading(false);
      input.value = "";
      return;
    }

    setIsLoading(false);
    setFieldValue(name, response.url);
  };

  const removeFile = (): void => {
    const input = document.getElementById(id) as HTMLInputElement;
    if (input) {
      input.value = "";
    }
    setFieldValue(name, null);
  };

  return (
    <FileUplaodStyles value={value}>
      <div className={`inputContainer ${touched && error && "error"}`}>
        <div className="valueContainer">
          {isLoading ? (
            <MoonLoader color={"#202650"} loading={isLoading} size={15} />
          ) : (
            <>
              {value && <FileIcon style={{ marginRight: 8 }} />}
              <p className={`value ${value && "valueTextColor"}`}>
                {value ? value : placeholder}
              </p>
            </>
          )}
        </div>
        {value ? (
          <Button
            className="delete"
            buttonDesignType={buttonTypes.DANGER}
            onClick={removeFile}
          >
            <Trash />
          </Button>
        ) : (
          <Button className="addButton" onClick={openFileUpload}>
            + Add File
          </Button>
        )}
      </div>
      <input
        type="file"
        name={name}
        id={id}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <p className="errorMessage">{touched && error && error}</p>
    </FileUplaodStyles>
  );
};

export default FileUpload;
