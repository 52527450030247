import { TextFieldStyles } from "./textField.styles";

import { TFProps } from "./textField.types";

const TextField = ({
  onChange,
  value,
  error,
  touched,
  onBlur,
  placeholder,
  id,
  type = "text",
  name,
}: TFProps) => {
  return (
    <TextFieldStyles>
      <input
        autoComplete="off"
        id={id}
        name={name}
        type={type}
        className={`input ${touched && error && "error"}`}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      <p className="errorMessage">{touched && error && error}</p>
    </TextFieldStyles>
  );
};

export default TextField;
