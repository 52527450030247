// Dtos
import { ManufacturerDto } from "../dtos/Manufacturer.dto";

// Domain
import { Manufacturer } from "../../app/domain/Manufacturer";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TrailerManufacturersResponseDataType = {
  trailer_manufacturers: ManufacturerDto[];
  statusType: ResponseStatusType;
};

export class TrailerManufacturersResponse {
  manufacturers: Manufacturer[];
  statusType: ResponseStatusType;
  constructor(responseData: TrailerManufacturersResponseDataType) {
    this.manufacturers = responseData.trailer_manufacturers.map(
      (manufacturer) => new Manufacturer(manufacturer)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
