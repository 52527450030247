interface TrailerTypeFormType {
  name: string;
}

export class TrailerTypeFormModel {
  name: string;

  constructor(data?: TrailerTypeFormType) {
    this.name = data?.name || "";
  }
}
