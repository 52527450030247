// Components
import { Paragraph } from "../../typography";
import { Button, StatusPill } from "../../UI";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Models
import { Business } from "../../../models/app/domain/Business";

// Utils
import { isEmpty } from "../../../utils/global.utils";

// Styles
import { BusinessesItemStyles } from "./businesses-item.styles";

type props = {
  onClick?: () => void;
  detailsAction?: () => void;
  business: Business;
};

const BusinessItem = ({ onClick, detailsAction, business }: props) => {
  return (
    <BusinessesItemStyles onClick={onClick}>
      <Paragraph className="ID Id">{business.id}</Paragraph>
      <Paragraph className="name userText">{business.name}</Paragraph>
      <div className="status userText">
        <StatusPill status={business.verificationStatus} />
      </div>
      {isEmpty(business?.proofDocument?.url) ? (
        <Paragraph className="proofDocument date">/</Paragraph>
      ) : (
        <Paragraph
          className="proofDocument"
          style={{
            cursor: business?.proofDocument?.url && "pointer",
            color: business?.proofDocument?.url && "#0000EE",
          }}
          onClick={() => {
            business?.proofDocument?.url &&
              window.open(business.proofDocument.url, "_blank");
          }}
        >
          {business?.proofDocument?.url}
        </Paragraph>
      )}
      <Paragraph className="taxId serviceTypeText">{business.taxId}</Paragraph>

      <div className="actions">
        <Button
          onClick={detailsAction}
          className="actionButtons"
          buttonDesignType={buttonDesignType.SECONDARY}
        >
          See Details
        </Button>
      </div>
    </BusinessesItemStyles>
  );
};

export default BusinessItem;
