interface props {
  style: object;
}

const FileIcon = ({ style }: props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
      style={{ ...style }}
    >
      <path
        fill="#172B4D"
        d="M2 0C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6H2zm7 7V1.5L14.5 7H9z"
      ></path>
    </svg>
  );
};

export default FileIcon;
