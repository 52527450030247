interface FeatureFormType {
  title: string;
}

export class FeatureFormModel {
  title: string;

  constructor(data?: FeatureFormType) {
    this.title = data?.title || "";
  }
}
