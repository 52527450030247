// Dtos
import { BranchDto } from "../dtos/Branch.dto";

// Domain
import { Branch } from "../../app/domain/Branch";
// enums
import { ResponseStatusType } from "../../../enums/api";
import { PaginationDto } from "../dtos/Pagination.dto";
import { Pagination } from "../../app/domain/Pagination";

export type BranchesResponseDataType = {
  branches: BranchDto[];
  pagination: PaginationDto;
  statusType: ResponseStatusType;
};

export class BranchesResponse {
  branches: Branch[];
  pagination: Pagination;
  statusType: ResponseStatusType;

  constructor(responseData: BranchesResponseDataType) {
    this.branches = responseData.branches.map((branch) => new Branch(branch));
    this.pagination = new Pagination(responseData.pagination);
    this.statusType = ResponseStatusType.OK;
  }
}
