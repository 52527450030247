// Dtos
import { ManufacturerDto } from "../dtos/Manufacturer.dto";

// Domain
import { Manufacturer } from "../../app/domain/Manufacturer";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TrailerManufacturerResponseDataType = {
  trailer_manufacturer: ManufacturerDto;
  statusType: ResponseStatusType;
};

export class TrailerManufacturerResponse {
  manufacturer: Manufacturer;
  statusType: ResponseStatusType;
  constructor(responseData: TrailerManufacturerResponseDataType) {
    this.manufacturer = new Manufacturer(responseData.trailer_manufacturer);
    this.statusType = ResponseStatusType.OK;
  }
}
