// Enums
import { ResponseStatusType } from "../../../enums/api";

export type MessageResponseType = {
  message: string;
  statusType: ResponseStatusType;
};

export class MessageResponse {
  message: string;
  statusType: ResponseStatusType;

  constructor(responseData: MessageResponseType) {
    this.message = responseData.message;
    this.statusType = ResponseStatusType.OK;
  }
}
