import { AuthDTO } from "../../api/dtos/Auth.dto";

// Utils
import { getItem } from "../../../utils/local-storage.utils";

// Constants
import * as localStorageConsts from "../../../constants/local-storage.constants";

export class Auth {
  accessToken?: string;
  refreshToken?: string;
  expiresAt?: number;
  createdAt?: number;

  constructor(entity?: AuthDTO) {
    this.accessToken =
      entity?.access_token || getItem(localStorageConsts.TOKEN);
    this.refreshToken =
      entity?.refresh_token || getItem(localStorageConsts.REFRESH_TOKEN);
    this.expiresAt = entity?.expires_at;
    this.createdAt = entity?.created_at;
  }
}
