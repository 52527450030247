import { ApiResponse } from "apisauce";

// Api
import { api } from "../api";
import { getGeneralApiProblem } from "../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem,
} from "../general-api-problems/general-api-problems.types";

import {
  AccountResponse,
  AccountResponseDataType,
  AccountsResponse,
  AccountsResponseDataType,
} from "../../models/api/responses";

import { AccountRequest } from "../../models/api/requests/account.requests";

import { AccountFormModel } from "../../models/app/form";

export const getAccounts = async (): Promise<
  AccountsResponse | GeneralApiProblem
> => {
  const response: ApiResponse<AccountsResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/account`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as AccountsResponseDataType;
  return new AccountsResponse(responseData);
};

export const createAccount = async (
  accountForm: AccountFormModel
): Promise<AccountResponse | GeneralApiProblem> => {
  const requestPayload = new AccountRequest(accountForm);

  const response: ApiResponse<AccountResponseDataType, ExceptionPayload> =
    await api.apisauce.post("/account", requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as AccountResponseDataType;

  return new AccountResponse(responseData);
};

export const updateAccount = async (
  accountForm: AccountFormModel,
  id?: number
): Promise<AccountResponse | GeneralApiProblem> => {
  const requestPayload = new AccountRequest(accountForm);

  const response: ApiResponse<AccountResponseDataType, ExceptionPayload> =
    await api.apisauce.patch(`/account/${id}`, requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as AccountResponseDataType;

  return new AccountResponse(responseData);
};
