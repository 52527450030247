import { ManufacturerFormModel } from "../../app/form/manufacturer.form.model";

export class ManufacturerRequest {
  image_url: string;
  manufacturer: string;

  constructor(manufacturerForm: ManufacturerFormModel) {
    this.image_url = manufacturerForm.imageUrl;
    this.manufacturer = manufacturerForm.manufacturer;
  }
}
