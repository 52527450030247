// components
import { Label } from "../../typography";
// icons
import { CheckBoxIcon } from "../../../SVGS";
// styles
import { CheckboxStyles } from "./checkbox.styles";

interface props {
  value: boolean;
  handleChange: () => void;
  label?: string;
  style?: object;
}

const Checkbox = ({ value, style, handleChange, label }: props) => {
  return (
    <CheckboxStyles style={{ ...style }}>
      <div
        onClick={handleChange}
        className={`checkboxMask ${value && "checked"}`}
      >
        {value && <CheckBoxIcon />}
      </div>
      <Label className="label">{label}</Label>
    </CheckboxStyles>
  );
};

export default Checkbox;
