import { ApiResponse } from "apisauce";

// Api
import { api } from "../api";
import { getGeneralApiProblem } from "../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem,
} from "../general-api-problems/general-api-problems.types";

import { FeatureFormModel, PlanFormModel } from "../../models/app/form";

import {
  CreateFeatureRequest,
  UpdateFeatureRequest,
  PlanRequest,
} from "../../models/api/requests";

import {
  FeatureResponse,
  FeatureResponseDataType,
  FeaturesResponse,
  FeaturesResponseDataType,
  MessageResponse,
  MessageResponseType,
  SubscriptionPlansResponse,
  SubscriptionPlansResponseDataType,
  SubscriptionPlanResponse,
  SubscriptionPlanResponseDataType,
} from "../../models/api/responses";

export const getSubscriptionFeaturesRequest = async (): Promise<
  FeaturesResponse | GeneralApiProblem
> => {
  const response: ApiResponse<FeaturesResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/store/subscription-plans/features`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as FeaturesResponseDataType;
  return new FeaturesResponse(responseData);
};

export const createSubscriptionFeatureRequest = async (
  featureForm: FeatureFormModel
): Promise<FeatureResponse | GeneralApiProblem> => {
  const requestPayload = new CreateFeatureRequest(featureForm);

  const response: ApiResponse<FeatureResponseDataType, ExceptionPayload> =
    await api.apisauce.post(
      "/store/subscription-plans/features",
      requestPayload
    );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as FeatureResponseDataType;

  return new FeatureResponse(responseData);
};

export const updateSubscriptionFeatureRequest = async (
  featureForm: FeatureFormModel,
  id?: number
): Promise<FeatureResponse | GeneralApiProblem> => {
  const requestPayload = new UpdateFeatureRequest(featureForm);

  const response: ApiResponse<FeatureResponseDataType, ExceptionPayload> =
    await api.apisauce.patch(
      `/store/subscription-plans/features/${id}`,
      requestPayload
    );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as FeatureResponseDataType;

  return new FeatureResponse(responseData);
};

export const deleteSubscriptionFeatureRequest = async (
  id: number
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/store/subscription-plans/features/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;

  return new MessageResponse(responseData);
};

export const getSubscriptionPlansRequest = async (): Promise<
  SubscriptionPlansResponse | GeneralApiProblem
> => {
  const response: ApiResponse<
    SubscriptionPlansResponseDataType,
    ExceptionPayload
  > = await api.apisauce.get(`/store/subscription-plans`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as SubscriptionPlansResponseDataType;
  return new SubscriptionPlansResponse(responseData);
};

export const createSubscriptionPlan = async (
  values: PlanFormModel
): Promise<SubscriptionPlanResponse | GeneralApiProblem> => {
  const requestPayload = new PlanRequest(values);
  const response: ApiResponse<
    SubscriptionPlanResponseDataType,
    ExceptionPayload
  > = await api.apisauce.post(`/store/subscription-plans`, requestPayload);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as SubscriptionPlanResponseDataType;
  return new SubscriptionPlanResponse(responseData);
};

export const updateSubscriptionPlan = async (
  values: PlanFormModel,
  id?: number
): Promise<SubscriptionPlanResponse | GeneralApiProblem> => {
  const requestPayload = new PlanRequest(values);
  const response: ApiResponse<
    SubscriptionPlanResponseDataType,
    ExceptionPayload
  > = await api.apisauce.patch(
    `/store/subscription-plans/${id}`,
    requestPayload
  );

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as SubscriptionPlanResponseDataType;
  return new SubscriptionPlanResponse(responseData);
};

export const deletePlan = async (
  id: number
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/store/subscription-plans/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;

  return new MessageResponse(responseData);
};

export const activateSubscriptionPlan = async (
  id: number
): Promise<SubscriptionPlanResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    SubscriptionPlanResponseDataType,
    ExceptionPayload
  > = await api.apisauce.post(`/store/subscription-plans/${id}/activate`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as SubscriptionPlanResponseDataType;
  return new SubscriptionPlanResponse(responseData);
};
