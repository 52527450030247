type NavigationLink = {
  path: string;
  name: string;
  // Icon: SvgIconComponent;
};

export const navigation: NavigationLink[] = [
  {
    path: "/",
    name: "Truck Manufacturers",
    //   Icon: PermMedia
  },
  {
    path: "/trailer-manufacturers",
    name: "Trailer Manufacturers",
    //   Icon: AccessibilityIcon
  },
  {
    path: "/trailer-types",
    name: "Trailer-types",
    //   Icon: Store
  },
  {
    path: "/businesses",
    name: "Business",
    //   Icon: FitnessCenterIcon
  },
  {
    path: "/branches",
    name: "Branches",
    //   Icon: FitnessCenterIcon
  },
  {
    path: "/users",
    name: "Users",
    //   Icon: AccountCircle
  },
  {
    path: "/payment-system",
    name: "Payment system",
    //   Icon: AccountCircle
  },
];
