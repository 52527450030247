// Dtos
import { SubscriptionPlanDto } from "../dtos/SubscriptionPlan.dto";

// Domain
import { SubscriptionPlan } from "../../app/domain/SubscriptionPlan";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type SubscriptionPlanResponseDataType = {
  subscription_plan: SubscriptionPlanDto;
  statusType: ResponseStatusType;
};

export class SubscriptionPlanResponse {
  subscriptionPlan: SubscriptionPlan;
  statusType: ResponseStatusType;
  constructor(responseData: SubscriptionPlanResponseDataType) {
    this.subscriptionPlan = new SubscriptionPlan(
      responseData.subscription_plan
    );
    this.statusType = ResponseStatusType.OK;
  }
}
