// Dtos
import { FileDto } from "../../api/dtos/File.dto";

export class File {
  url: string;
  key: string;
  type: string;
  mimeType: string;
  constructor(entity: FileDto) {
    this.url = entity.url;
    this.key = entity.key;
    this.type = entity.type;
    this.mimeType = entity.mime_type;
  }
}
