import styled from "styled-components";

export const SubscriptionFeaturesStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
  }
  .tableHeader {
    position: relative;
    z-index: 2;
    padding: 16px 24px 7px 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);

    .listSubtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #202650;
      margin-bottom: 32px;
    }

    .tableHead {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .head {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #9fadc3;
  }

  .id {
    width: 33%;
    margin-right: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .title {
    width: 33%;
    margin-right: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .actions {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .deleteButton {
      margin-left: 8px;
      height: 39px;
    }

    .deleteButton:hover path {
      fill: #ffffff;
    }
  }

  .body {
    padding: 0;
    position: relative;
    z-index: 1;
  }

  .modalCard {
    width: 400px !important;
  }
`;
