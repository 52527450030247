// styles
import { CardStyles } from "./card.styles";

// Types
import { CardProps } from "./card.types";

const Card = ({ children, style, className, rounded }: CardProps) => (
  <CardStyles rounded={rounded} style={{ ...style }} className={className}>
    {children}
  </CardStyles>
);

export default Card;
