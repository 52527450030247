import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// Components
import {
  PageHeader,
  Card,
  Body,
  Header,
  Row,
  Button,
  CentralModal,
} from "../components/UI";
import { Heading2, Paragraph } from "../components/typography";
import AccountItem from "../components/lists/users/account-item";

// Containers
import AccountForm from "../containers/forms/account/account.form";

// Models
import { Account } from "../models/app/domain/Account";

// Calls
import {
  getAccounts,
  createAccount,
  updateAccount,
} from "../api/calls/account";

// Bulders
import { usersHeadBuilder } from "../builders/users.builder";
import * as buttonDesignType from "../builders/button-design.types";

// Enums
import { ResponseStatusType } from "../enums/api";

// Utils
import { errorHandler } from "../utils/error.utils";

// Styles
import { AccountStyles } from "../pagesStyles/users.styles";
import { AccountFormModel } from "../models/app/form";

type PageStateTypes = {
  users: Account[];
  modalType?: string;
  chosenAccount?: Account;
};

const Users = () => {
  const [pageState, setPageState] = useState<PageStateTypes>({
    users: [],
    modalType: undefined,
    chosenAccount: undefined,
  });

  const getUsers = async (): Promise<void> => {
    const response = await getAccounts();

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    setPageState((prevState) => ({
      ...prevState,
      users: response.accounts,
    }));
  };

  const closeModal = () => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: undefined,
      chosenAccount: undefined,
    }));
  };

  const addAccount = () => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: "ADD_ACCOUNT",
    }));
  };

  const createAcconutHandler = async (
    newAccount: AccountFormModel
  ): Promise<void> => {
    const response = await createAccount(newAccount);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Account created!");
    setPageState((prevState) => ({
      users: [...prevState.users, response.account],
      modalType: undefined,
      chosenAccount: undefined,
    }));
  };

  const editAccount = (account: Account): void => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: "EDIT_ACCOUNT",
      chosenAccount: account,
    }));
  };

  const updateAccountHandler = async (
    updatedAccount: AccountFormModel
  ): Promise<void> => {
    const response = await updateAccount(
      updatedAccount,
      pageState.chosenAccount?.id
    );
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Account updated!");
    setPageState((prevState) => ({
      users: prevState.users.map((user) =>
        user.id === pageState.chosenAccount?.id ? response.account : user
      ),
      modalType: undefined,
      chosenManufacturer: undefined,
    }));
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <AccountStyles>
      <PageHeader title="Users" />
      <Card className="card">
        <Header className="header">
          <Heading2>ALL USERS</Heading2>
          <Button
            buttonDesignType={buttonDesignType.PRIMARY}
            onClick={addAccount}
          >
            Add User
          </Button>
        </Header>
        <Body className="tableHead">
          {usersHeadBuilder.map(({ title, className }, i) => (
            <Paragraph className={`tableHeadTitle ${className}`} key={i}>
              {title}
            </Paragraph>
          ))}
        </Body>
      </Card>

      <Card className="manufacturers">
        <Body className="list">
          {pageState.users.map((user) => (
            <Row key={user.id} style={{ cursor: "pointer" }}>
              <AccountItem user={user} editAction={() => editAccount(user)} />
            </Row>
          ))}
        </Body>
      </Card>

      {pageState.modalType && (
        <CentralModal className="modalCard" closeModal={closeModal}>
          <Card>
            <AccountForm
              updateAccount={updateAccountHandler}
              createAccount={createAcconutHandler}
              modalType={pageState.modalType}
              closeModal={closeModal}
              chosenAccount={pageState.chosenAccount}
            />
          </Card>
        </CentralModal>
      )}
    </AccountStyles>
  );
};

export default Users;
