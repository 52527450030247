import styled from "styled-components";
// builders
import * as buttonDesignTypes from "../../../builders/button-design.types";

type buttonDesignType = {
  buttonDesignType?: string;
};

export const ButtonStyles = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  padding: 10px;

  ${({ buttonDesignType }: buttonDesignType) => {
    switch (buttonDesignType) {
      case buttonDesignTypes.PRIMARY:
        return `
        background: #202650;
        color: #fff;
        border: 1px solid #202650;
      `;

      case buttonDesignTypes.SECONDARY:
        return `
        background: #FAFBFC;
        color: #172B4D;
        border: 1px solid #DFE1E6;
      `;

      case buttonDesignTypes.DANGER:
        return `
        background: #F1F1F5;
        color: #000000;
        border: 1px solid #F1F1F5;
        padding: 6px 9px;
      `;

      default:
        return `
        background: #F1F1F5;
        color: #696974;
        border: 1px solid #F1F1F5;
      `;
    }
  }};

  &:hover {
    ${({ buttonDesignType }: buttonDesignType) => {
      switch (buttonDesignType) {
        case buttonDesignTypes.PRIMARY:
          return `
        background: #3B5274;
        color: #fff;
        border: 1px solid #3B5274;
      `;

        case buttonDesignTypes.SECONDARY:
          return `
        background: #F5FAFF;
        color: #172B4D;
        border: 1px solid #DFE1E6;
      `;

        case buttonDesignTypes.DANGER:
          return `
        background: #E42E2D;
        color: #FFFFFF;
        border: 1px solid #E42E2D;
      `;

        default:
          return `
        background: #F9F9F9;
        color: #696974;
        border: 1px solid #F9F9F9;
      `;
      }
    }};
  }

  &:disabled {
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    color: #172b4d;
    opacity: 0.2;
  }
`;
