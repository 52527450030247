// Dtos
import { BusinessDto } from "../../api/dtos/Business.dto";

// Domain Models
import { File } from "./File";

export class Business {
  id: number;
  name: string;
  taxId: string;
  websiteUrl: string;
  reviewedBy: number;
  reviewedAt: string;
  verificationStatus: string;
  proofDocument: File | null;

  constructor(entity: BusinessDto) {
    this.id = entity.id;
    this.name = entity.name;
    this.taxId = entity.tax_id;
    this.websiteUrl = entity.website_url;
    this.reviewedBy = entity.reviewed_by;
    this.reviewedAt = entity.reviewed_at;
    this.verificationStatus = entity.verification_status;
    this.proofDocument = entity.proof_document
      ? new File(entity.proof_document)
      : null;
  }
}
