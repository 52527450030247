import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";

// Components
import {
  Header,
  Body,
  Button,
  CentralModal,
  Card,
} from "../../../components/UI";
import { Heading2, Paragraph } from "../../../components/typography";
import SubscriptionPlanItem from "../../../components/lists/payment/subscription-plans/Subscription-plan-item";

// Containers
import PlanForm from "../../forms/payment/plans/Plan-form";

// Form Models
import { PlanFormModel } from "../../../models/app/form";

// Domain Models
import { SubscriptionPlan } from "../../../models/app/domain/SubscriptionPlan";

// Calls
import {
  getSubscriptionPlansRequest,
  createSubscriptionPlan,
  updateSubscriptionPlan,
  deletePlan,
  activateSubscriptionPlan,
} from "../../../api/calls/payment";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// enums
import { ResponseStatusType } from "../../../enums/api";

// Utils
import { errorHandler } from "../../../utils/error.utils";

// Styles
import { SubscriptionPlansStyles } from "./subscription-plans.styles";

interface SubscriptionPlansState {
  subscriptionPlans: SubscriptionPlan[];
  modalType?: string;
  chosenPlan?: SubscriptionPlan;
}

const SubscriptionPlans = () => {
  const [subscriptionPlansState, setSubscriptionPlansState] =
    useState<SubscriptionPlansState>({
      subscriptionPlans: [],
      modalType: undefined,
      chosenPlan: undefined,
    });

  const getSubscriptionPlans = async () => {
    const response = await getSubscriptionPlansRequest();

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    setSubscriptionPlansState((prevState) => ({
      ...prevState,
      subscriptionPlans: response.subscriptionPlans,
    }));
  };

  const closeModal = (): void => {
    setSubscriptionPlansState((prevState) => ({
      ...prevState,
      modalType: undefined,
      chosenPlan: undefined,
    }));
  };

  const addPlan = (): void => {
    setSubscriptionPlansState((prevState) => ({
      ...prevState,
      modalType: "ADD_PLAN",
    }));
  };

  const createPlan = async (planForm: PlanFormModel): Promise<void> => {
    const response = await createSubscriptionPlan(planForm);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Plan created!");
    setSubscriptionPlansState((prevState) => ({
      subscriptionPlans: [
        ...prevState.subscriptionPlans,
        response.subscriptionPlan,
      ],
      modalType: undefined,
      chosenPlan: undefined,
    }));
  };

  const editPlan = (plan: SubscriptionPlan): void => {
    setSubscriptionPlansState((prevState) => ({
      ...prevState,
      modalType: "EDIT_PLAN",
      chosenPlan: plan,
    }));
  };

  const updatePlan = async (planForm: PlanFormModel): Promise<void> => {
    const response = await updateSubscriptionPlan(
      planForm,
      subscriptionPlansState?.chosenPlan?.id
    );
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Plan updated!");
    setSubscriptionPlansState((prevState) => ({
      subscriptionPlans: prevState.subscriptionPlans.map((plan) =>
        plan.id === response.subscriptionPlan.id
          ? response.subscriptionPlan
          : plan
      ),
      modalType: undefined,
      chosenPlan: undefined,
    }));
  };

  const deleteSubscriptionPlan = async (id: number): Promise<void> => {
    const result = await swal({
      title: "Delete Subscription Plan?",
      text: "Are you sure you want to delete this plan?",
      buttons: [true, true],
      dangerMode: true,
    });
    if (!result) {
      return;
    }

    const response = await deletePlan(id);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success(response.message);
    setSubscriptionPlansState((prevState) => ({
      ...prevState,
      subscriptionPlans: prevState.subscriptionPlans.filter(
        (plan) => plan.id !== id
      ),
    }));
  };

  const activatePlan = async (id: number): Promise<void> => {
    const response = await activateSubscriptionPlan(id);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Plan activated!");
    setSubscriptionPlansState((prevState) => ({
      subscriptionPlans: prevState.subscriptionPlans.map((plan) =>
        plan.id === response.subscriptionPlan.id
          ? response.subscriptionPlan
          : plan
      ),
      modalType: undefined,
      chosenPlan: undefined,
    }));
  };

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  return (
    <SubscriptionPlansStyles>
      <Header className="header">
        <Heading2>SUBSCRIPTION PLANS</Heading2>
        <Button onClick={addPlan} buttonDesignType={buttonDesignType.PRIMARY}>
          Add Subscription Plan
        </Button>
      </Header>
      <Header className="tableHeader">
        <Paragraph className="listSubtitle">
          List Of Subscription Plans
        </Paragraph>
      </Header>
      <Body className="body">
        {subscriptionPlansState.subscriptionPlans.map((plan) => (
          <SubscriptionPlanItem
            key={plan.id}
            {...plan}
            activateAction={() => activatePlan(plan.id)}
            deleteAction={() => deleteSubscriptionPlan(plan.id)}
            editAction={() => editPlan(plan)}
          />
        ))}
      </Body>

      {subscriptionPlansState.modalType && (
        <CentralModal className="modalCard" closeModal={closeModal}>
          <Card>
            <PlanForm
              createFeature={createPlan}
              updateFeature={updatePlan}
              closeModal={closeModal}
              chosenPlan={subscriptionPlansState.chosenPlan}
              modalType={subscriptionPlansState.modalType}
            />
          </Card>
        </CentralModal>
      )}
    </SubscriptionPlansStyles>
  );
};

export default SubscriptionPlans;
