import { useFormik } from "formik";

// Components
import {
  Body,
  Header,
  TextField,
  Button,
  Footer,
  FileUpload,
} from "../../../components/UI";
import { Heading2, Label } from "../../../components/typography";

// Icons
import { Close } from "../../../SVGS";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Form Model
import { ManufacturerFormModel } from "../../../models/app/form";

// Domain Model
import { Manufacturer } from "../../../models/app/domain/Manufacturer";

// Validation
import { manufacturerValidate } from "../../validations/manufacturer.validations";

// Styles
import { ManufacturerFormStyles } from "./manufacturer.styles";
import { useEffect, useState } from "react";

type props = {
  createManufacturer: (newManufacturer: ManufacturerFormModel) => void;
  updateManufacturer: (updatedManufacturer: ManufacturerFormModel) => void;
  modalType: string;
  closeModal: () => void;
  chosenManufacturer?: Manufacturer;
};

const ManufacturerForm = ({
  createManufacturer,
  updateManufacturer,
  modalType,
  closeModal,
  chosenManufacturer,
}: props) => {
  const [initialState, setInitialState] = useState<ManufacturerFormModel>(
    new ManufacturerFormModel()
  );
  const formSubmitHandler = (values: ManufacturerFormModel): void => {
    if (modalType === "ADD_MANUFACTURER") {
      createManufacturer(values);
    }
    if (modalType === "EDIT_MANUFACTURER") {
      updateManufacturer(values);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: formSubmitHandler,
    validate: manufacturerValidate,
  });

  useEffect(() => {
    if (chosenManufacturer) {
      setInitialState(chosenManufacturer.generateForm());
    }
  }, []);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur,
  } = formik;

  const generateTitle = () => {
    switch (modalType) {
      case "ADD_MANUFACTURER":
        return "ADD MANUFACTURER";
      case "EDIT_MANUFACTURER":
        return "EDIT MANUFACTURER";
      default:
        return "";
    }
  };

  const generateButtonTitle = () => {
    switch (modalType) {
      case "ADD_MANUFACTURER":
        return "Create Manufacturer";
      case "EDIT_MANUFACTURER":
        return "Save Changes";
      default:
        return "";
    }
  };

  return (
    <ManufacturerFormStyles>
      <Header className="formHeader">
        <Heading2>{generateTitle()}</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <form autoComplete="new-password" onSubmit={handleSubmit}>
        <Body>
          <div className="inputContainer">
            <Label>Manufacturer name</Label>
            <TextField
              name="manufacturer"
              value={values.manufacturer}
              touched={touched.manufacturer}
              error={errors.manufacturer}
              onBlur={handleBlur}
              onChange={handleChange}
              className="input"
              placeholder="Enter a manufacturer name"
            />
          </div>
          <div className="inputContainer">
            <Label>Manufacturer name</Label>
            <FileUpload
              id="imageUrl"
              name="imageUrl"
              value={values.imageUrl}
              setFieldValue={setFieldValue}
              touched={touched.imageUrl}
              error={errors.imageUrl}
            />
          </div>
        </Body>
        <Footer>
          <div className="buttonContainer">
            <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
              {generateButtonTitle()}
            </Button>
          </div>
        </Footer>
      </form>
    </ManufacturerFormStyles>
  );
};

export default ManufacturerForm;
