import { PlanFormModel } from "../../app/form";

// Request
import { PriceRequest } from "./price.request";
import { SubscriptionPlanFeatureRequest } from "./subscription-plan-feature.request";

export class PlanRequest {
  name: string;
  description: string;
  access_to_analytics: boolean;
  allowed_number_of_branches: number;
  gives_premium_access: boolean;
  price: PriceRequest;
  subscription_plan_features: SubscriptionPlanFeatureRequest[];

  constructor(planForm: PlanFormModel) {
    this.name = planForm.name;
    this.description = planForm.description;
    this.access_to_analytics = true;
    this.gives_premium_access = planForm.givesPremiumAccess;
    this.allowed_number_of_branches = planForm.allowedNumberOfBranches;
    this.price = new PriceRequest(planForm.price);
    this.subscription_plan_features = planForm.subscriptionPlanFeatures.map(
      (feature) => new SubscriptionPlanFeatureRequest(feature)
    );
  }
}
