import styled from "styled-components";

// Constants
import * as color from "../../constants/color.constants";

export const Heading1Styles = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;

export const Heading2Styles = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  /* word-break: break-all; */
  letter-spacing: 1px;
  color: ${color.PRIMARY_COLOR};
`;

export const LabelStyles = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;

export const ParagraphStyles = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;

export const SmallTextStyles = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;

export const SpanStyles = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;
