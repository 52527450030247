// Dtos
import { FeatureDto } from "../dtos/Feature.dto";

// Domain
import { Feature } from "../../app/domain/Feature";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type FeatureResponseDataType = {
  subscription_feature: FeatureDto;
  statusType: ResponseStatusType;
};

export class FeatureResponse {
  feature: Feature;
  statusType: ResponseStatusType;
  constructor(responseData: FeatureResponseDataType) {
    this.feature = new Feature(responseData.subscription_feature);
    this.statusType = ResponseStatusType.OK;
  }
}
