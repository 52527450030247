/**
 * Checks is value empty, null or undefined
 * If value is array, checks is length of value greater of zero
 * @param value - The value it checks
 * @returns Is value empty
 */
export const isEmpty = (value: any): boolean => {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "null" ||
    value === "undefined"
  ) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return false;
};

/**
 * Returns a new object containing only keys that are not empty.
 * @param object - Object to evaluate.
 * @returns New object with defined keys.
 */
export const filterEmptyKeys = (
  object: Record<string, any>
): Record<string, any> => {
  if (isEmpty(object)) return {};
  const filteredObject: any = {};

  for (const key of Object.keys(object)) {
    if (!isEmpty(object[key])) filteredObject[key] = object[key];
  }

  return filteredObject;
};
