import styled from "styled-components";

export const AccountStyles = styled.section`
  min-height: calc(100vh - 42px);

  .card {
    margin-top: 24px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tableHead {
      display: flex;
      align-items: center;

      .tableHeadTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #9fadc3;
      }
    }
  }

  .ID {
    /* width: 15%; */
    width: 33%;
  }

  .email {
    /* width: 20%; */
    width: 33%;
  }

  .actions {
    /* width: 18%; */
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .list {
    padding: 0;
  }

  .manufacturers {
    margin-top: 24px;
  }

  .modalCard {
    width: 400px !important;
  }
`;
