import { ApiResponse } from "apisauce";

// Api
import { api } from "../api";
import { getGeneralApiProblem } from "../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem,
} from "../general-api-problems/general-api-problems.types";

// Request Models
import { BranchSearchRequest } from "../../models/api/requests";

import {
  BranchesResponseDataType,
  BranchesResponse,
  MessageResponseType,
} from "../../models/api/responses";
import { ResponseStatusType } from "../../enums/api";

export const searchBranchesRequest = async (
  page: number,
  perPage: number,
  branchSearchRequest: BranchSearchRequest
): Promise<BranchesResponse | GeneralApiProblem> => {
  const response: ApiResponse<BranchesResponseDataType, ExceptionPayload> =
    await api.apisauce.get(
      `/branches?pagination[per_page]=${perPage}&pagination[page]=${page}`,
      branchSearchRequest
    );

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as BranchesResponseDataType;
  console.log(responseData);
  return new BranchesResponse(responseData);
};

export const setFeaturedBranch = async (
  id?: number
): Promise<any | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.post(`/branches/${id}/featured`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  return { statusType: ResponseStatusType.OK };
};

export const unsetFeaturedBranch = async (
  id?: number
): Promise<any | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/branches/${id}/featured`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  return { statusType: ResponseStatusType.OK };
};
