import { useEffect, useState } from "react";

// Components
import {
  PageHeader,
  Card,
  Body,
  Header,
  Row,
  CentralModal,
} from "../components/UI";
import { Heading2, Paragraph } from "../components/typography";
import BusinessItem from "../components/lists/businesses/businesses-item";
import Pagination from "../components/lists/pagination/pagination";

// Constants
import * as verificationStatus from "../constants/business.constants";

// Containers
import BusinessDetails from "../containers/business/business-details";

// Models
import { Business } from "../models/app/domain/Business";

// Calls
import { getBusinessesRequest, setBusinessReview } from "../api/calls/business";

// Bulders
import { businessesHeadBuilder } from "../builders/business.builder";

// Enums
import { ResponseStatusType } from "../enums/api";

// Utils
import { errorHandler } from "../utils/error.utils";

// Styles
import { BusinessesStyles } from "../pagesStyles/businesses.styles";
import { PaginationStyles } from "../components/lists/pagination/pagination.styles";

type PageStateTypes = {
  businesses: Business[];
  currentPage: number;
  perPage: number;
  totalPages: number;
  chosenBusiness?: Business;
};

const Businesses = () => {
  const [pageState, setPageState] = useState<PageStateTypes>({
    businesses: [],
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
    chosenBusiness: undefined,
  });

  const getBusinesses = async (
    currentPage: number,
    perPage: number
  ): Promise<void> => {
    const response = await getBusinessesRequest(currentPage, perPage);

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    setPageState((prevState) => ({
      ...prevState,
      businesses: response.businesses,
      currentPage: response.pagination.page,
      totalPages: response.pagination.totalPages,
    }));
  };

  const closeModal = (): void => {
    setPageState((prevState) => ({
      ...prevState,
      chosenBusiness: undefined,
    }));
  };

  const detailsAction = (business: Business): void => {
    setPageState((prevState) => ({
      ...prevState,
      chosenBusiness: business,
    }));
  };

  const acceptBusiness = async (): Promise<void> => {
    const response = await setBusinessReview(
      verificationStatus.VERIFIED,
      pageState.chosenBusiness?.id
    );
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    setPageState((prevState) => ({
      ...prevState,
      businesses: prevState.businesses.map((business) =>
        business.id === response.business.id ? response.business : business
      ),
      chosenBusiness: undefined,
    }));
  };

  const declineBusiness = async (): Promise<void> => {
    const response = await setBusinessReview(
      verificationStatus.NOT_VERIFIED,
      pageState.chosenBusiness?.id
    );
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    setPageState((prevState) => ({
      ...prevState,
      businesses: prevState.businesses.map((business) =>
        business.id === response.business.id ? response.business : business
      ),
      chosenBusiness: undefined,
    }));
  };

  const setCurrentPage = (page: number) => {
    setPageState((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  useEffect(() => {
    getBusinesses(pageState.currentPage, pageState.perPage);
  }, [pageState.currentPage, pageState.perPage]);

  return (
    <BusinessesStyles>
      <PageHeader title="Businesses" />
      <Card className="card">
        <Header className="header">
          <Heading2>ALL BUSINESSES</Heading2>
        </Header>
        <Body className="tableHead">
          {businessesHeadBuilder.map(({ title, className }, i) => (
            <Paragraph className={`tableHeadTitle ${className}`} key={i}>
              {title}
            </Paragraph>
          ))}
        </Body>
      </Card>

      <Card className="manufacturers">
        <Body className="list">
          {pageState.businesses.map((business) => (
            <Row key={business.id} style={{ cursor: "pointer" }}>
              <BusinessItem
                business={business}
                detailsAction={() => detailsAction(business)}
              />
            </Row>
          ))}
        </Body>
        <PaginationStyles>
          <Pagination
            totalPages={pageState.totalPages}
            currentPage={pageState.currentPage}
            setCurrentPage={setCurrentPage}
          />
        </PaginationStyles>
      </Card>

      {pageState.chosenBusiness && (
        <CentralModal className="modalCard" closeModal={closeModal}>
          <Card>
            <BusinessDetails
              acceptAction={acceptBusiness}
              declineAction={declineBusiness}
              closeModal={closeModal}
              businessDetails={pageState.chosenBusiness}
            />
          </Card>
        </CentralModal>
      )}
    </BusinessesStyles>
  );
};

export default Businesses;
