import styled from "styled-components";

interface fullLayout {
  fullLayout: boolean;
}

export const PageWrapperStyles = styled.div`
  width: ${({ fullLayout }: fullLayout) =>
    fullLayout ? "100%" : "calc(100% - 210px)"};
  margin-left: ${({ fullLayout }: fullLayout) =>
    fullLayout ? "0px" : "210px"};
  padding: 21px 24px;
  background: #f8f9fd;
`;
