// Dtos
import { FeatureDto } from "../dtos/Feature.dto";

// Domain
import { Feature } from "../../app/domain/Feature";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type FeaturesResponseDataType = {
  subscription_features: FeatureDto[];
  statusType: ResponseStatusType;
};

export class FeaturesResponse {
  features: Feature[];
  statusType: ResponseStatusType;
  constructor(responseData: FeaturesResponseDataType) {
    this.features = responseData.subscription_features.map(
      (feature) => new Feature(feature)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
