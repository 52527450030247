import styled from "styled-components";

export const LoginStyles = styled.section`
  display: flex;
  height: 100vh;
  background: #ffffff;

  .formContainer {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 100px 0;

    .logo {
      min-width: 176px;
      min-height: 151px;
    }
  }

  .sliderContainer {
    width: 50%;
    height: 100%;
  }

  .forgotPasswordModal {
    width: 420px;

    .modalHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
