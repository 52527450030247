// Dtos
import { SubscriptionPlanFeatureDto } from "../../api/dtos/SubscriptionPlanFeature.dto";

import { Feature } from "./Feature";

export class SubscriptionPlanFeature {
  supported: boolean;
  subscriptionPlanFeature: Feature;

  constructor(entity: SubscriptionPlanFeatureDto) {
    this.supported = entity.supported;
    this.subscriptionPlanFeature = new Feature(entity.subscription_feature);
  }
}
