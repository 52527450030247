// Dtos
import { TrailerTypeDto } from "../dtos/Trailer-types.dto";

// Domain
import { TrailerType } from "../../app/domain/TrailerType";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TrailerTypeResponseDataType = {
  trailer_type: TrailerTypeDto;
  statusType: ResponseStatusType;
};

export class TrailerTypeResponse {
  trailerType: TrailerType;
  statusType: ResponseStatusType;
  constructor(responseData: TrailerTypeResponseDataType) {
    this.trailerType = new TrailerType(responseData.trailer_type);
    this.statusType = ResponseStatusType.OK;
  }
}
