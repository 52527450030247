type validationProps = {
  email: string;
  password: string;
};
export const loginValidate = ({ email, password }: validationProps): any => {
  const errors: any = {};
  if (!email) {
    errors.email = "* Required";
  }

  if (!password) {
    errors.password = "* Required";
  }

  return errors;
};
