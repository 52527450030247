export const trailerTypesHeadBuilder = [
  {
    className: "ID",
    title: "ID",
  },
  {
    className: "name",
    title: "Name",
  },
  {
    className: "actions",
    title: "",
  },
];
