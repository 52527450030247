// Types
import { Heading1Props } from "./all-typography.types";

// Styles
import { Heading1Styles } from "./all-typography.styles";

const Heading1 = ({ children, className, style }: Heading1Props) => {
  return (
    <Heading1Styles className={className} style={style}>
      {children}
    </Heading1Styles>
  );
};

export default Heading1;
