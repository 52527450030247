import { ApiResponse } from "apisauce";

// Api
import { api } from "../api";
import {
  ExceptionPayload,
  GeneralApiProblem,
} from "../general-api-problems/general-api-problems.types";
import { getGeneralApiProblem } from "../general-api-problems/general-api-problems";

// Requests
import { FileUploadRequest } from "../../models/api/requests";

// Response
import {
  FileUploadResponse,
  FileUploadResponseType,
} from "../../models/api/responses/file-upload.response";

/**
 * File upload request
 * @param file - File
 * @returns FileUploadResponse or GeneralApiProblem
 */
export const fileUpload = async (
  file: File
): Promise<FileUploadResponse | GeneralApiProblem> => {
  const requestPayload = new FileUploadRequest(file);

  const response: ApiResponse<FileUploadResponseType, ExceptionPayload> =
    await api.apisauce.post("/file-management/upload", requestPayload.payload);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as FileUploadResponseType;
  return new FileUploadResponse(responseData);
};
