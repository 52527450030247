// Dtos
import { PaginationDto } from "../../api/dtos/Pagination.dto";

export class Pagination {
  perPage: number;
  page: number;
  totalRecords: number;
  totalPages: number;
  hasMore: boolean;

  constructor(entity: PaginationDto) {
    this.perPage = entity.per_page;
    this.page = entity.page;
    this.totalRecords = entity.total_records;
    this.totalPages = entity.total_pages;
    this.hasMore = entity.has_more;
  }
}
