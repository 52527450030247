// Dtos
import { TrailerTypeDto } from "../dtos/Trailer-types.dto";

// Domain
import { TrailerType } from "../../app/domain/TrailerType";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TrailerTypesResponseDataType = {
  trailer_types: TrailerTypeDto[];
  statusType: ResponseStatusType;
};

export class TrailerTypesResponse {
  trailerTypes: TrailerType[];
  statusType: ResponseStatusType;
  constructor(responseData: TrailerTypesResponseDataType) {
    this.trailerTypes = responseData.trailer_types.map(
      (type) => new TrailerType(type)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
