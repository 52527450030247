// Types
import { ParagraphProps } from "./all-typography.types";

// Styles
import { ParagraphStyles } from "./all-typography.styles";

const Paragraph = ({ children, className, onClick, style }: ParagraphProps) => (
  <ParagraphStyles className={className} onClick={onClick} style={{ ...style }}>
    {children}
  </ParagraphStyles>
);

export default Paragraph;
