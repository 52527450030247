import { Header, Body, Footer, Button, StatusPill } from "../../components/UI";
import { Heading2, Label, Paragraph } from "../../components/typography";

import * as buttonDesignType from "../../builders/button-design.types";

import * as verificationStatus from "../../constants/business.constants";

import { Business } from "../../models/app/domain/Business";

import { Close } from "../../SVGS";

// Utils
import { isEmpty } from "../../utils/global.utils";

import { BusinessDetailsStyles } from "./business-details.styles";

interface props {
  businessDetails: Business;
  closeModal: () => void;
  acceptAction: () => void;
  declineAction: () => void;
}

const BusinessDetails = ({
  businessDetails,
  closeModal,
  acceptAction,
  declineAction,
}: props) => {
  return (
    <BusinessDetailsStyles>
      <Header className="header">
        <Heading2>BUSINESS DETAILS</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body className="body">
        <div className="content">
          <div className="detail">
            <Label>ID:</Label>
            <Paragraph className="para">{businessDetails.id}</Paragraph>
          </div>
          <div className="detail">
            <Label>Name:</Label>
            <Paragraph className="para">{businessDetails.name}</Paragraph>
          </div>
          <div className="detail">
            <Label>Tax ID:</Label>
            <Paragraph className="para">{businessDetails.taxId}</Paragraph>
          </div>
          <div className="detail">
            <Label>Reviewed by:</Label>
            <Paragraph className="para">
              {isEmpty(businessDetails.reviewedBy)
                ? "/"
                : businessDetails.reviewedBy}
            </Paragraph>
          </div>
          <div className="detail">
            <Label>Reviewed at:</Label>
            <Paragraph className="para">
              {isEmpty(businessDetails.reviewedAt)
                ? "/"
                : new Date(businessDetails.reviewedAt).toLocaleDateString()}
            </Paragraph>
          </div>
          <div className="detail">
            <Label>Business proof:</Label>
            <Paragraph
              className="para"
              style={{
                cursor: businessDetails?.proofDocument?.url && "pointer",
                color: businessDetails?.proofDocument?.url && "#0000EE",
              }}
              onClick={() => {
                businessDetails?.proofDocument?.url &&
                  window.open(businessDetails.proofDocument.url, "_blank");
              }}
            >
              {isEmpty(businessDetails?.proofDocument?.url)
                ? "/"
                : businessDetails?.proofDocument?.url}
            </Paragraph>
          </div>
          <div className="detail">
            <Label>Website:</Label>
            <Paragraph
              className="para"
              style={{
                cursor: businessDetails?.websiteUrl && "pointer",
                color: businessDetails?.websiteUrl && "#0000EE",
              }}
              onClick={() => {
                businessDetails?.websiteUrl &&
                  window.open(businessDetails.websiteUrl, "_blank");
              }}
            >
              {isEmpty(businessDetails.websiteUrl)
                ? "/"
                : businessDetails.websiteUrl}
            </Paragraph>
          </div>
          <div className="detail">
            <Label>Status:</Label>
            <div className="para">
              <StatusPill status={businessDetails.verificationStatus} />
            </div>
          </div>
        </div>
      </Body>
      <Footer className="footer">
        {businessDetails.verificationStatus !== verificationStatus.VERIFIED && (
          <Button
            onClick={acceptAction}
            style={{ marginLeft: 15, width: 100, padding: 8 }}
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            Accept
          </Button>
        )}
        {businessDetails.verificationStatus !==
          verificationStatus.NOT_VERIFIED && (
          <Button
            onClick={declineAction}
            style={{ marginLeft: 15, width: 100, padding: 8 }}
            buttonDesignType={buttonDesignType.DANGER}
          >
            Decline
          </Button>
        )}
      </Footer>
    </BusinessDetailsStyles>
  );
};

export default BusinessDetails;
