// Dtos
import { TrailerTypeDto } from "../../api/dtos/Trailer-types.dto";

// Form Models
import { TrailerTypeFormModel } from "../form";

export class TrailerType {
  id: number;
  name: string;

  constructor(entity: TrailerTypeDto) {
    this.id = entity.id;
    this.name = entity.name;
  }

  generateForm(): TrailerTypeFormModel {
    return {
      name: this.name || "",
    };
  }
}
