interface validationParams {
  imageUrl: string;
  manufacturer: string;
}
export const manufacturerValidate = ({
  imageUrl,
  manufacturer,
}: validationParams): any => {
  const errors: any = {};
  if (!imageUrl) {
    errors.imageUrl = "* Required";
  }

  if (!manufacturer) {
    errors.manufacturer = "* Required";
  }

  return errors;
};
