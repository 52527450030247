import { useFormik } from "formik";
import { useEffect, useState } from "react";

// Components
import {
  Body,
  Header,
  TextField,
  Button,
  Footer,
} from "../../../components/UI";
import { Heading2, Label } from "../../../components/typography";

// Icons
import { Close } from "../../../SVGS";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Form Model
import { AccountFormModel } from "../../../models/app/form";

// Domain Model
import { Account } from "../../../models/app/domain/Account";

// Validation
import { accountValidate } from "../../validations/account.validations";

// Styles
import { AccountFormStyles } from "./account-form.styles";

type props = {
  createAccount: (newAccount: AccountFormModel) => void;
  updateAccount: (updatedAccount: AccountFormModel) => void;
  modalType: string;
  closeModal: () => void;
  chosenAccount?: Account;
};

const AccountForm = ({
  createAccount,
  updateAccount,
  modalType,
  closeModal,
  chosenAccount,
}: props) => {
  const [initialState, setInitialState] = useState<AccountFormModel>(
    new AccountFormModel()
  );
  const formSubmitHandler = (values: AccountFormModel): void => {
    if (modalType === "ADD_ACCOUNT") {
      createAccount(values);
    }
    if (modalType === "EDIT_ACCOUNT") {
      updateAccount(values);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: formSubmitHandler,
    validate: accountValidate,
  });

  useEffect(() => {
    if (chosenAccount) {
      setInitialState(chosenAccount.generateForm());
    }
  }, []);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  const generateTitle = () => {
    switch (modalType) {
      case "ADD_ACCOUNT":
        return "ADD ACCOUNT";
      case "EDIT_ACCOUNT":
        return "EDIT ACCOUNT";
      default:
        return "";
    }
  };

  const generateButtonTitle = () => {
    switch (modalType) {
      case "ADD_ACCOUNT":
        return "Create Account";
      case "EDIT_ACCOUNT":
        return "Save Changes";
      default:
        return "";
    }
  };

  return (
    <AccountFormStyles>
      <Header className="formHeader">
        <Heading2>{generateTitle()}</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <form autoComplete="new-password" onSubmit={handleSubmit}>
        <Body>
          <div className="inputContainer">
            <Label>Email</Label>
            <TextField
              name="email"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              className="input"
              placeholder="Enter a email"
            />
          </div>
        </Body>
        <Footer>
          <div className="buttonContainer">
            <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
              {generateButtonTitle()}
            </Button>
          </div>
        </Footer>
      </form>
    </AccountFormStyles>
  );
};

export default AccountForm;
