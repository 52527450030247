import styled from "styled-components";

const HeaderStyles = styled.header`
  padding: 24px;
  border-bottom: 1px solid #f1f1f5;
`;

type props = {
  children?: React.ReactNode;
  className?: string;
  style?: object;
};

const Header = ({ children, style, className }: props) => (
  <HeaderStyles style={{ ...style }} className={className}>
    {children}
  </HeaderStyles>
);

export default Header;
