import { useFormik } from "formik";

// Components
import {
  Body,
  Header,
  TextField,
  Button,
  Footer,
  FileUpload,
} from "../../../components/UI";
import { Heading2, Label } from "../../../components/typography";

// Icons
import { Close } from "../../../SVGS";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Form Model
import { TrailerTypeFormModel } from "../../../models/app/form";

// Domain Model
import { TrailerType } from "../../../models/app/domain/TrailerType";

// Validation
import { trailerTypeValidate } from "../../validations/trailer-type.validations";

// Styles
import { TrailerTypeFormStyles } from "./trailer-type-form.styles";
import { useEffect, useState } from "react";

type props = {
  createTrailerType: (newTrailerType: TrailerTypeFormModel) => void;
  updateTrailerType: (updatedTrailerType: TrailerTypeFormModel) => void;
  modalType: string;
  closeModal: () => void;
  chosenTrailerType?: TrailerType;
};

const TrailerTypeForm = ({
  createTrailerType,
  updateTrailerType,
  modalType,
  closeModal,
  chosenTrailerType,
}: props) => {
  const [initialState, setInitialState] = useState<TrailerTypeFormModel>(
    new TrailerTypeFormModel()
  );
  const formSubmitHandler = (values: TrailerTypeFormModel): void => {
    if (modalType === "ADD_TRAILER_TYPE") {
      createTrailerType(values);
    }
    if (modalType === "EDIT_TRAILER_TYPE") {
      updateTrailerType(values);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: formSubmitHandler,
    validate: trailerTypeValidate,
  });

  useEffect(() => {
    if (chosenTrailerType) {
      setInitialState(chosenTrailerType.generateForm());
    }
  }, []);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  const generateTitle = () => {
    switch (modalType) {
      case "ADD_TRAILER_TYPE":
        return "ADD TRAILER TYPE";
      case "EDIT_TRAILER_TYPE":
        return "EDIT TRAILER TYPE";
      default:
        return "";
    }
  };

  const generateButtonTitle = () => {
    switch (modalType) {
      case "ADD_TRAILER_TYPE":
        return "Create Trailer Type";
      case "EDIT_TRAILER_TYPE":
        return "Save Changes";
      default:
        return "";
    }
  };

  return (
    <TrailerTypeFormStyles>
      <Header className="formHeader">
        <Heading2>{generateTitle()}</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <form autoComplete="new-password" onSubmit={handleSubmit}>
        <Body>
          <div className="inputContainer">
            <Label>Trailer Type Name</Label>
            <TextField
              name="name"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              className="input"
              placeholder="Enter a trailer type name"
            />
          </div>
        </Body>
        <Footer>
          <div className="buttonContainer">
            <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
              {generateButtonTitle()}
            </Button>
          </div>
        </Footer>
      </form>
    </TrailerTypeFormStyles>
  );
};

export default TrailerTypeForm;
