import styled from "styled-components";

export const ManufacturerFormStyles = styled.div`
  .formHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inputsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inputContainer {
      width: 274px;

      .input {
        margin-top: 2px;
      }
    }
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
