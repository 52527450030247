import { useLocation } from "react-router-dom";

import { Auth } from "../../models/app/domain/Auth";

// Components
import NavLink from "./NavLink/NavLink";

import { TOKEN, REFRESH_TOKEN } from "../../constants/local-storage.constants";
import { removeItem } from "../../utils/local-storage.utils";
import history from "../../history";

// Icons
import { TruckFixLogo } from "../../SVGS";
// import {
//   Close,
//   Logo,
//   BranchesArrow,
//   ProfileIcon,
//   LogoutIcon,
// } from "../../SVGS";

// Configs
import { navigation } from "../../config/navigation.config";

// Styles
import { SideMenuStyles } from "./side-menu.styles";

interface props {
  setUserAuth: (value: Auth) => void;
}

const SideMenu = ({ setUserAuth }: props) => {
  const location = useLocation();

  return (
    <SideMenuStyles>
      <div className="sideMenu">
        <TruckFixLogo className="logo" />

        <div className="linksContainer">
          {navigation.map((linkProperties, i) => (
            <NavLink
              {...linkProperties}
              isActive={location.pathname === linkProperties.path}
              key={i}
            />
          ))}
        </div>
        <div className="profile">
          <NavLink
            name="Logout"
            onClick={() => {
              removeItem(TOKEN);
              removeItem(REFRESH_TOKEN);
              setUserAuth(new Auth());
              history.replace("/");
            }}
          />
        </div>
      </div>
    </SideMenuStyles>
  );
};

export default SideMenu;
