// Dtos
import { FeatureDto } from "../../api/dtos/Feature.dto";

// Form Model
import { FeatureFormModel } from "../form/feature.form";

export class Feature {
  id: number;
  title: string;

  constructor(entity: FeatureDto) {
    this.id = entity.id;
    this.title = entity.title;
  }

  generateForm(): FeatureFormModel {
    return {
      title: this.title || "",
    };
  }
}
