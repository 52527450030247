import styled from "styled-components";

export const SideMenuStyles = styled.div`
  height: 100vh;
  width: 210px;
  position: fixed;
  top: 0;
  z-index: 999;

  .sideMenu {
    width: 100%;
    height: 100%;
    background: #202650;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 4px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    padding: 42px 0 25px 0;
    position: relative;
    z-index: 2;

    .logo {
      margin: 0 auto;
      display: block;
      width: 100px;
      height: auto;
    }

    .brachContainer {
      width: 100%;
      margin: 35px 0 0 0;
      padding: 0 14px;
      cursor: pointer;
      min-height: 49px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .selectBranch {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #e5e5e5;
      }

      .chooseBracnhContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .chosenBranch {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.1px;
          color: #fafbfe;
        }

        .arrowContainer {
          display: flex;
          align-items: center;
          transition: all 0.3s ease-in-out;
        }

        .arrowAnim {
          transform: rotate(180deg);
        }
      }

      .address {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #9fadc3;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .linksContainer {
      margin-top: 38px;
    }

    .profile {
      width: 100%;
      position: absolute;
      bottom: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .imgContainer {
        margin-right: 9px;
      }

      .profileSettings {
        .userName {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: #f7f8fc;
        }

        .accSettings {
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 14px;
          color: #888c9a;
        }
      }
    }
  }

  .branchesContainer {
    width: calc(100vw - 210px);
    height: 100%;
    background: #00000033;
    position: absolute;
    top: 0;
    margin-left: 210px;

    .branchesCard {
      height: 100%;
      width: 337px;
      position: absolute;
      left: 0;
      animation: showModal 0.4s ease-in-out;

      .branchesHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .branchesBody {
        padding: 0;
        position: relative;
        z-index: 2;
      }
    }

    @keyframes showModal {
      from {
        transform: translateX(-337px);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .profileHeader {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #172b4d;
      margin-bottom: 4px;
    }

    .email {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #9fadc3;
    }
  }

  .profileBody {
    padding: 0px;
    display: flex;

    .button {
      height: 50px;
      width: 50%;
      border: none;
      outline: none;
      background: #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .buttonText {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #44444f;
        margin-left: 10px;
      }
    }

    .button:first-child {
      border-right: 1px solid #e1e3e7;
    }

    .button:hover {
      background: #172b4d0d;
    }
  }
`;
