import { useFormik } from "formik";
import { toast } from "react-toastify";
// Components
import { TextField, Button } from "../../../components/UI";
import { Label } from "../../../components/typography";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Form Models
import { LoginFormModel } from "../../../models/app/form";

// Domain Models
import { Auth } from "../../../models/app/domain/Auth";

// Services
import { loginValidate } from "../../validations/login.validations";

// Calls
import { loginRequest } from "../../../api/calls/login";

// Enums
import { ResponseStatusType } from "../../../enums/api";

// Utils
import { setItem } from "../../../utils/local-storage.utils";
import { errorHandler } from "../../../utils/error.utils";

// Constants
import * as localStorageConsts from "../../../constants/local-storage.constants";

// Styles
import { LoginFormStyles } from "./login.form.styles";

interface props {
  setUserAuth: (auth: Auth) => void;
}

const LoginForm = ({ setUserAuth }: props) => {
  const handleLogin = async (values: LoginFormModel): Promise<void> => {
    const response = await loginRequest(values);

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    setItem(localStorageConsts.TOKEN, response.auth.accessToken);
    setItem(localStorageConsts.REFRESH_TOKEN, response.auth.refreshToken);
    setUserAuth(response.auth);
  };

  const formik = useFormik({
    initialValues: new LoginFormModel(),
    onSubmit: handleLogin,
    validate: loginValidate,
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <LoginFormStyles>
      <form onSubmit={handleSubmit} className="form">
        <div>
          <Label style={{ marginBottom: 2 }}>Email</Label>
          <TextField
            name="email"
            type="email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <div className="passwordLabelContainer">
            <Label>Password</Label>
          </div>
          <TextField
            name="password"
            type="password"
            value={values.password}
            touched={touched.password}
            error={errors.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <Button
          type="submit"
          buttonDesignType={buttonDesignType.PRIMARY}
          style={{ width: "100%" }}
        >
          Log In
        </Button>
      </form>
    </LoginFormStyles>
  );
};

export default LoginForm;
