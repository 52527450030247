import { SubscriptionPlanFeatureForm } from "./subscription-plan-feature.form.model";

interface PlanFormType {
  name: string;
  description: string;
  price: number;
  allowedNumberOfBranches: number;
  accessToAnalytics: boolean;
  givesPremiumAccess: boolean;
  subscriptionPlanFeatures: SubscriptionPlanFeatureForm[];
}

export class PlanFormModel {
  name: string;
  description: string;
  price: number;
  allowedNumberOfBranches: number;
  accessToAnalytics: boolean;
  givesPremiumAccess: boolean;
  subscriptionPlanFeatures: SubscriptionPlanFeatureForm[];

  constructor(data?: PlanFormType) {
    this.name = data?.name || "";
    this.description = data?.description || "";
    this.price = data?.price || 0;
    this.allowedNumberOfBranches = data?.allowedNumberOfBranches || 0;
    this.accessToAnalytics = true;
    this.givesPremiumAccess = false;
    this.subscriptionPlanFeatures =
      data?.subscriptionPlanFeatures.map(
        (feature) => new SubscriptionPlanFeatureForm(feature)
      ) || [];
  }
}
