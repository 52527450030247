// Types
import { Heading2Props } from "./all-typography.types";

// Styles
import { Heading2Styles } from "./all-typography.styles";

const Heading2 = ({ children, className, style }: Heading2Props) => (
  <Heading2Styles className={className} style={{ ...style }}>
    {children}
  </Heading2Styles>
);

export default Heading2;
