import { ApisauceInstance, create } from "apisauce";
import { ApiConfig, DEFAULT_API_CONFIG } from "../config/api.config";

// utils
import * as localStorageUtil from "../utils/local-storage.utils";
import { isEmpty } from "../utils/global.utils";

//constants
import * as localStoageKeys from "../constants/local-storage.constants";

// import { logout } from "../Router";
// import { toast } from "react-toastify";
class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Param for pausing all calls untill refresh token finish issuing
   */
  isIssuingRefreshToken: boolean;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    // construct the apisauce instance
    let apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    apisauce.axiosInstance.interceptors.request.use(
      this.handleReqInterceptorSuccess
    );

    apisauce.axiosInstance.interceptors.response.use(
      this.handleResInterceptorSuccess,
      this.handleResInterceptorError
    );
    this.apisauce = apisauce;
    this.isIssuingRefreshToken = false;
  }

  /**
   * Gets token from localStorage and sets it in headers as Authorization
   * @param request The configuration to use.
   * @returns request with token.
   */
  handleReqInterceptorSuccess(request: any) {
    const token = localStorageUtil.getItem(localStoageKeys.TOKEN);
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  }

  handleResInterceptorSuccess(response: any) {
    return response;
  }

  handleResInterceptorError = async (error: any) => {
    const originalRequest = error.config;

    if ([403].includes(error.response.status)) {
      //   toast.error("Forbidden!");
      //   logout();
    }

    if (
      [401].includes(error.response.status) &&
      originalRequest.url !== `/auth/refresh` &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      if (isEmpty(localStorageUtil.getItem(localStoageKeys.REFRESH_TOKEN))) {
        // toast.error("Unauthorized!");
        // logout();
      } else {
        if (!this.isIssuingRefreshToken) {
          await this.refreshAccessToken(originalRequest);
          return this.retryOriginalRequest(originalRequest);
        } else {
          await this.waitForRefreshToken();
          return this.retryOriginalRequest(originalRequest);
        }
      }
    }

    return Promise.reject(error);
  };

  retryOriginalRequest = (originalRequest: any): any => {
    if (originalRequest.method === "get") {
      return this.apisauce.get(originalRequest.url);
    }
    if (originalRequest.method === "post") {
      return this.apisauce.post(originalRequest.url, originalRequest.data);
    }
    if (originalRequest.method === "patch") {
      return this.apisauce.patch(originalRequest.url, originalRequest.data);
    }
    if (originalRequest.method === "delete") {
      return this.apisauce.delete(originalRequest.url);
    }
  };

  // Function for pausing code until refresh token is resolved in another api instance
  async waitForRefreshToken() {
    if (!this.isIssuingRefreshToken) return;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await this.waitForRefreshToken();
  }

  async refreshAccessToken(request: any) {
    this.isIssuingRefreshToken = true;

    const refreshToken = localStorageUtil.getItem(
      localStoageKeys.REFRESH_TOKEN
    );

    const payload = {
      refresh_token: refreshToken,
    };

    try {
      const response: any = await this.apisauce.post("/auth/refresh", payload);

      const newToken = response.data.auth.access_token;
      const newRefreshToken = response.data.auth.refresh_token;

      if (response.status === 201 && newToken && newRefreshToken) {
        request.headers.Authorization = `Bearer ${newToken}`;
        localStorageUtil.setItem(localStoageKeys.TOKEN, newToken);
        localStorageUtil.setItem(
          localStoageKeys.REFRESH_TOKEN,
          newRefreshToken
        );
      }
    } catch (error) {
      localStorageUtil.removeItem(localStoageKeys.TOKEN);
      localStorageUtil.removeItem(localStoageKeys.REFRESH_TOKEN);
      //   toast.error("Unauthorized!");
      //   logout();
    }
    this.isIssuingRefreshToken = false;
  }
}

export const api = new Api();
