import styled from "styled-components";

export const BranchesStyles = styled.section`
  min-height: calc(100vh - 42px);

  .card {
    margin-top: 24px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tableHead {
      display: flex;
      align-items: center;

      .tableHeadTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #9fadc3;
      }
    }
  }

  .ID {
    width: 15%;
  }

  .name {
    width: 40%;
  }

  .address {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    width: 25%;
    display: flex;
    justify-content: flex-end;
  }

  .list {
    padding: 0;
  }

  .nameWrapper {
    width: 32%;
    display: inline-block;
  }

  .addressWrapper {
    width: 32%;
    margin-left: 5%;
    display: inline-block;
  }

  .actionButtons {
    display: inline-block;
    margin-left: 5%;
  }

  .actionButton {
    margin-left: 5px;
  }
`;
