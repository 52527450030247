// styles
import { TextareaStyles } from "./textarea.styles";

interface props {
  value: string;
  name: string;
  onChange: (name: string, val: string) => void;
  onBlur: (name: string, val: boolean) => void;
  id?: string;
  error?: string;
  touched?: boolean;
  className?: string;
  placeholder?: string;
}

const Textarea = ({
  value,
  name,
  onChange,
  onBlur,
  placeholder,
  id,
  error,
  touched,
  className,
}: props) => {
  return (
    <TextareaStyles>
      <textarea
        autoComplete="off"
        id={id}
        name={name}
        className={`textarea ${className && className} ${
          touched && error && "error"
        }`}
        onChange={(e) => onChange(name, e.target.value)}
        value={value}
        onBlur={() => onBlur(name, true)}
        placeholder={placeholder}
      />
      <p className="errorMessage">{touched && error && error}</p>
    </TextareaStyles>
  );
};

export default Textarea;
