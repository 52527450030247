// Dtos
import { SubscriptionPlanDto } from "../dtos/SubscriptionPlan.dto";

// Domain
import { SubscriptionPlan } from "../../app/domain/SubscriptionPlan";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type SubscriptionPlansResponseDataType = {
  subscription_plans: SubscriptionPlanDto[];
  statusType: ResponseStatusType;
};

export class SubscriptionPlansResponse {
  subscriptionPlans: SubscriptionPlan[];
  statusType: ResponseStatusType;
  constructor(responseData: SubscriptionPlansResponseDataType) {
    this.subscriptionPlans = responseData.subscription_plans.map(
      (plan) => new SubscriptionPlan(plan)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
