import * as tabsValues from "../constants/payment.constants";

interface tab {
  tabName: string;
  value: string;
}

export const tabsBuilder: tab[] = [
  {
    tabName: "Subscription Plans",
    //   icon: <CircledGeneralSettingsIcon />,
    value: tabsValues.SUBSCRIPTION_PLANS,
  },
  {
    tabName: "Subscription Features",
    //   icon: <CircledServiceTypeIcon />,
    value: tabsValues.SUBSCRIPTION_FEATURES,
  },
];

interface featuresTableHead {
  className: string;
  title: string;
}

export const subscriptionFeaturesTableHead: featuresTableHead[] = [
  {
    className: "id",
    title: "ID",
  },
  {
    className: "title",
    title: "Title",
  },
  {
    className: "actions",
    title: "",
  },
];
