import styled from "styled-components";

export const TextareaStyles = styled.div`
  width: 100%;

  .textarea {
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #00000099;
    padding: 9px 8px;
    height: 162px;
    min-height: 162px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    resize: none;
    outline: none;
  }

  .textarea:focus {
    border: 1px solid #2f76ff;
  }

  .error {
    border: 1px solid #ca2c2c !important;
  }

  .textarea::placeholder {
    opacity: 0.5;
  }

  .errorMessage {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ca2c2c;
    margin-top: 4px;
    height: 24px;
  }
`;
