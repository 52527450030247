import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
// Components
import {
  PageHeader,
  Card,
  Body,
  Header,
  Row,
  Button,
  CentralModal,
} from "../components/UI";
import { Heading2, Paragraph } from "../components/typography";
import TrailerTypeItem from "../components/lists/trailer-types/trailer-type-item";

// Containers
import TrailerTypeForm from "../containers/forms/trailer-type/trailer-type.form";

// Form Models
import { TrailerTypeFormModel } from "../models/app/form";

// Models
import { TrailerType } from "../models/app/domain/TrailerType";

// Calls
import {
  getTrailerTypes,
  createTrailerTypeRequest,
  updateTrailerTypeRequest,
  deleteTrailerTypeRequest,
} from "../api/calls/trailer-types";

// Builders
import { trailerTypesHeadBuilder } from "../builders/trailer-types.builder";
import * as buttonDesignType from "../builders/button-design.types";

// Enums
import { ResponseStatusType } from "../enums/api";

// Utils
import { errorHandler } from "../utils/error.utils";

// Styles

import { TrailerTypesStyles } from "../pagesStyles/trailer-types.styles";

type PageStateTypes = {
  trailerTypes: TrailerType[];
  modalType?: string;
  chosenTrailerType?: TrailerType;
};

const TrailerTypes = () => {
  const [pageState, setPageState] = useState<PageStateTypes>({
    trailerTypes: [],
    modalType: undefined,
    chosenTrailerType: undefined,
  });

  const getTypes = async (): Promise<void> => {
    const response = await getTrailerTypes();

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    setPageState((prevState) => ({
      ...prevState,
      trailerTypes: response.trailerTypes,
    }));
  };

  const closeModal = () => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: undefined,
      chosenTrailerType: undefined,
    }));
  };

  const addTrailerType = (): void => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: "ADD_TRAILER_TYPE",
    }));
  };

  const createTrailerType = async (
    newTrailerType: TrailerTypeFormModel
  ): Promise<void> => {
    const response = await createTrailerTypeRequest(newTrailerType);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Trailer type created!");
    setPageState((prevState) => ({
      trailerTypes: [...prevState.trailerTypes, response.trailerType],
      modalType: undefined,
      chosenTrailerType: undefined,
    }));
  };

  const editTrailerType = (trailerType: TrailerType): void => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: "EDIT_TRAILER_TYPE",
      chosenTrailerType: trailerType,
    }));
  };

  const updateTrailerType = async (
    updatedTrailerType: TrailerTypeFormModel
  ): Promise<void> => {
    const response = await updateTrailerTypeRequest(
      updatedTrailerType,
      pageState.chosenTrailerType?.id
    );
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Trailer type updated!");
    setPageState((prevState) => ({
      trailerTypes: prevState.trailerTypes.map((type) =>
        type.id === pageState.chosenTrailerType?.id
          ? response.trailerType
          : type
      ),
      modalType: undefined,
      chosenManufacturer: undefined,
    }));
  };

  const deleteTrailerType = async (id: number): Promise<void> => {
    const result = await swal({
      title: "Delete Trailer Type?",
      text: "Are you sure you want to delete this trailer type?",
      buttons: [true, true],
      dangerMode: true,
    });
    if (!result) {
      return;
    }

    const response = await deleteTrailerTypeRequest(id);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success(response.message);
    setPageState((prevState) => ({
      ...prevState,
      trailerTypes: prevState.trailerTypes.filter((type) => type.id !== id),
    }));
  };

  useEffect(() => {
    getTypes();
  }, []);

  return (
    <TrailerTypesStyles>
      <PageHeader title="Trailer Types" />
      <Card className="card">
        <Header className="header">
          <Heading2>ALL TYPES</Heading2>
          <Button
            buttonDesignType={buttonDesignType.PRIMARY}
            onClick={addTrailerType}
          >
            Add Trailer Type
          </Button>
        </Header>
        <Body className="tableHead">
          {trailerTypesHeadBuilder.map(({ title, className }, i) => (
            <Paragraph className={`tableHeadTitle ${className}`} key={i}>
              {title}
            </Paragraph>
          ))}
        </Body>
      </Card>

      <Card className="manufacturers">
        <Body className="list">
          {pageState.trailerTypes.map((type) => (
            <Row key={type.id} style={{ cursor: "pointer" }}>
              <TrailerTypeItem
                trailerType={type}
                editAction={() => editTrailerType(type)}
                declineAction={() => deleteTrailerType(type.id)}
              />
            </Row>
          ))}
        </Body>
      </Card>

      {pageState.modalType && (
        <CentralModal className="modalCard" closeModal={closeModal}>
          <Card>
            <TrailerTypeForm
              updateTrailerType={updateTrailerType}
              createTrailerType={createTrailerType}
              modalType={pageState.modalType}
              closeModal={closeModal}
              chosenTrailerType={pageState.chosenTrailerType}
            />
          </Card>
        </CentralModal>
      )}
    </TrailerTypesStyles>
  );
};

export default TrailerTypes;
