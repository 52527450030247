// Components
import { Paragraph } from "../../typography";
import { Button } from "../../UI";

// Builders
import * as buttonDesignType from "../../../builders/button-design.types";

// Models
import { Account } from "../../../models/app/domain/Account";

// Styles
import { AccountItemStyles } from "./account-item.styles";

type props = {
  onClick?: () => void;
  editAction?: () => void;
  declineAction?: () => void;
  user: Account;
};

const AccountItem = ({ onClick, editAction, declineAction, user }: props) => {
  return (
    <AccountItemStyles onClick={onClick}>
      <Paragraph className="ID Id">{user.id}</Paragraph>
      <Paragraph className="email userText">{user.email}</Paragraph>

      <div className="actions">
        <Button
          className="actionButtons"
          style={{ marginRight: 12 }}
          buttonDesignType={buttonDesignType.SECONDARY}
          onClick={editAction}
        >
          Edit
        </Button>
        <Button
          onClick={declineAction}
          className="actionButtons"
          buttonDesignType={buttonDesignType.DANGER}
        >
          Delete
        </Button>
      </div>
    </AccountItemStyles>
  );
};

export default AccountItem;
