// Dtos
import { ManufacturerDto } from "../../api/dtos/Manufacturer.dto";

// Domain Models
import { File } from "./File";

// Form Models
import { ManufacturerFormModel } from "../form";

export class Manufacturer {
  id: number;
  manufacturer: string;
  type: string;
  image?: File;

  constructor(entity: ManufacturerDto) {
    this.id = entity.id;
    this.manufacturer = entity.manufacturer;
    this.type = entity.type;
    this.image = entity.image ? new File(entity.image) : undefined;
  }

  generateForm(): ManufacturerFormModel {
    return {
      manufacturer: this.manufacturer || "",
      imageUrl: this.image?.url || "",
    };
  }
}
