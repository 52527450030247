export const manufacturersHeadBuilder = [
  {
    className: "ID",
    title: "ID",
  },
  {
    className: "name",
    title: "Name",
  },
  {
    className: "type",
    title: "Type",
  },
  {
    className: "image",
    title: "Image",
  },
  {
    className: "actions",
    title: "",
  },
];
