import styled from "styled-components";

const BodyStyles = styled.div`
  padding: 24px;
`;

type props = {
  children?: React.ReactNode;
  className?: string;
  style?: object;
};

const Body = ({ children, style, className }: props) => (
  <BodyStyles style={{ ...style }} className={className}>
    {children}
  </BodyStyles>
);

export default Body;
