// Types
import { ButtonProps } from "./button.types";

// styles
import { ButtonStyles } from "./button.styles";

const Button = ({
  type = "button",
  id,
  className,
  onClick,
  buttonDesignType,
  children,
  style,
  disabled,
}: ButtonProps) => (
  <ButtonStyles
    type={type}
    id={id}
    className={className}
    style={{ ...style }}
    onClick={onClick}
    buttonDesignType={buttonDesignType}
    disabled={disabled}
  >
    {children}
  </ButtonStyles>
);

export default Button;
