// Dtos
import { BusinessDto } from "../dtos/Business.dto";
import { PaginationDto } from "../dtos/Pagination.dto";

// Domain
import { Business } from "../../app/domain/Business";
import { Pagination } from "../../app/domain/Pagination";

// Enums
import { ResponseStatusType } from "../../../enums/api";

export type BusinessesResponseDataType = {
  businesses: BusinessDto[];
  pagination: PaginationDto;
  statusType: ResponseStatusType;
};

export class BusinessesResponse {
  businesses: Business[];
  pagination: Pagination;
  statusType: ResponseStatusType;
  constructor(responseData: BusinessesResponseDataType) {
    this.businesses = responseData.businesses.map(
      (business) => new Business(business)
    );
    this.pagination = new Pagination(responseData.pagination);
    this.statusType = ResponseStatusType.OK;
  }
}
