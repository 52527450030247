import { useEffect, useState } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";

// Components
import {
  PageHeader,
  Card,
  Body,
  Header,
  Row,
  Button,
  CentralModal,
} from "../components/UI";
import { Heading2, Paragraph } from "../components/typography";
import ManufacturerItem from "../components/lists/manufacturer/manufacturer-item";

// Containers
import ManufacturerForm from "../containers/forms/manufacturer/manufacturer.form";

// Form Models
import { ManufacturerFormModel } from "../models/app/form";

// Models
import { Manufacturer } from "../models/app/domain/Manufacturer";

// Calls
import {
  getTrailerManufacturers,
  createTrailerManufacturerRequest,
  updateTrailerManufacturerRequest,
  deleteManufacturerRequest,
} from "../api/calls/trailer-manufacturers";

// Bulders
import { manufacturersHeadBuilder } from "../builders/manufacturers.builder";
import * as buttonDesignType from "../builders/button-design.types";

// Enums
import { ResponseStatusType } from "../enums/api";

// Utils
import { errorHandler } from "../utils/error.utils";

import { TrailerManmStyles } from "../pagesStyles/trailer-manufacturers.styles";

type PageStateTypes = {
  manufacturers: Manufacturer[];
  modalType?: string;
  chosenManufacturer?: Manufacturer;
};

const TrailerMan = () => {
  const [pageState, setPageState] = useState<PageStateTypes>({
    manufacturers: [],
    modalType: undefined,
    chosenManufacturer: undefined,
  });

  const getManufacturers = async (): Promise<void> => {
    const response = await getTrailerManufacturers();

    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }

    setPageState((prevState) => ({
      ...prevState,
      manufacturers: response.manufacturers,
    }));
  };

  const closeModal = () => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: undefined,
      chosenManufacturer: undefined,
    }));
  };

  const addManufacturer = () => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: "ADD_MANUFACTURER",
    }));
  };

  const createManufacturer = async (
    newManufacturer: ManufacturerFormModel
  ): Promise<void> => {
    const response = await createTrailerManufacturerRequest(newManufacturer);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Trailer manufacturer created!");
    setPageState((prevState) => ({
      manufacturers: [...prevState.manufacturers, response.manufacturer],
      modalType: undefined,
      chosenManufacturer: undefined,
    }));
  };

  const editManufacturer = (manufacturer: Manufacturer): void => {
    setPageState((prevState) => ({
      ...prevState,
      modalType: "EDIT_MANUFACTURER",
      chosenManufacturer: manufacturer,
    }));
  };

  const updateManufacturer = async (
    updatedManufacturer: ManufacturerFormModel
  ): Promise<void> => {
    const response = await updateTrailerManufacturerRequest(
      updatedManufacturer,
      pageState.chosenManufacturer?.id
    );
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success("Trailer manufacturer updated!");
    setPageState((prevState) => ({
      manufacturers: prevState.manufacturers.map((man) =>
        man.id === pageState.chosenManufacturer?.id
          ? response.manufacturer
          : man
      ),
      modalType: undefined,
      chosenManufacturer: undefined,
    }));
  };

  const deleteManufacturer = async (id: number): Promise<void> => {
    const result = await swal({
      title: "Delete Trailer Manufacturer?",
      text: "Are you sure you want to delete this manufacturer?",
      buttons: [true, true],
      dangerMode: true,
    });
    if (!result) {
      return;
    }

    const response = await deleteManufacturerRequest(id);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      return;
    }
    toast.success(response.message);
    setPageState((prevState) => ({
      ...prevState,
      manufacturers: prevState.manufacturers.filter((man) => man.id !== id),
    }));
  };

  useEffect(() => {
    getManufacturers();
  }, []);

  return (
    <TrailerManmStyles>
      <PageHeader title="Trailer Manufacturers" />
      <Card className="card">
        <Header className="header">
          <Heading2>ALL MANUFACTURERS</Heading2>
          <Button
            buttonDesignType={buttonDesignType.PRIMARY}
            onClick={addManufacturer}
          >
            Add Manufacturer
          </Button>
        </Header>
        <Body className="tableHead">
          {manufacturersHeadBuilder.map(({ title, className }, i) => (
            <Paragraph className={`tableHeadTitle ${className}`} key={i}>
              {title}
            </Paragraph>
          ))}
        </Body>
      </Card>

      <Card className="manufacturers">
        <Body className="list">
          {pageState.manufacturers.map((man) => (
            <Row key={man.id} style={{ cursor: "pointer" }}>
              <ManufacturerItem
                manufacturer={man}
                editAction={() => editManufacturer(man)}
                deleteAction={() => deleteManufacturer(man.id)}
              />
            </Row>
          ))}
        </Body>
      </Card>

      {pageState.modalType && (
        <CentralModal className="modalCard" closeModal={closeModal}>
          <Card>
            <ManufacturerForm
              chosenManufacturer={pageState.chosenManufacturer}
              updateManufacturer={updateManufacturer}
              createManufacturer={createManufacturer}
              modalType={pageState.modalType}
              closeModal={closeModal}
            />
          </Card>
        </CentralModal>
      )}
    </TrailerManmStyles>
  );
};

export default TrailerMan;
