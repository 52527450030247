import { useState, useRef } from "react";
// icons
import { DropDownArrow } from "../../../SVGS";
// styles
import { ExpandingDescriptionStyles } from "./expandingDescription.styles";

interface props {
  title: string;
  text: string;
}

const ExpandingDescription = ({ title, text }: props) => {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const element = useRef(null);

  const collapseSection = (chosenElement: HTMLElement | null): void => {
    if (chosenElement) {
      const sectionHeight = chosenElement.scrollHeight;
      const elementTransition = chosenElement.style.transition;
      chosenElement.style.transition = "";
      requestAnimationFrame(() => {
        chosenElement.style.height = `${sectionHeight}px`;
        chosenElement.style.transition = elementTransition;
        requestAnimationFrame(() => {
          chosenElement.style.height = `${0}px`;
        });
      });
    }
  };

  const expandSection = (chosenElement: HTMLElement | null): void => {
    if (chosenElement) {
      const sectionHeight = chosenElement?.scrollHeight;
      chosenElement.style.height = `${sectionHeight}px`;
    }
  };

  return (
    <ExpandingDescriptionStyles>
      <div className="titleAndArrow">
        <h5 className="title">{title}</h5>
        <DropDownArrow
          onClick={() => {
            setIsDescriptionVisible((prevState) => !prevState);
            if (isDescriptionVisible) {
              collapseSection(element.current);
            } else {
              expandSection(element.current);
            }
          }}
          className={`arrow  ${isDescriptionVisible && "arrowUp"}`}
        />
      </div>
      <p ref={element} className="description">
        {text}
      </p>
    </ExpandingDescriptionStyles>
  );
};

export default ExpandingDescription;
