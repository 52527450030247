// Dtos
import { SubscriptionPlanDto } from "../../api/dtos/SubscriptionPlan.dto";

import { Price } from "./Price";
import { SubscriptionPlanFeature } from "./SubscriptionPlanFeature";

import { PlanFormModel } from "../form";

export class SubscriptionPlan {
  id: number;
  name: string;
  description: string;
  recommended: boolean;
  active: boolean;
  allowedNumberOfBranches: number;
  accessToAnalytics: boolean;
  givesPremiumAccess: boolean;
  price: Price;
  subscriptionPlanFeatures: SubscriptionPlanFeature[];

  constructor(entity: SubscriptionPlanDto) {
    this.id = entity.id;
    this.name = entity.name;
    this.description = entity.description;
    this.recommended = entity.recommended;
    this.active = entity.active;
    this.allowedNumberOfBranches = entity.allowed_number_of_branches;
    this.accessToAnalytics = entity.access_to_analytics;
    this.givesPremiumAccess = entity.gives_premium_access;
    this.price = new Price(entity.price);
    this.subscriptionPlanFeatures = entity.subscription_plan_features.map(
      (spf) => new SubscriptionPlanFeature(spf)
    );
  }

  generateForm(): PlanFormModel {
    return {
      name: this.name,
      description: this.description,
      price: this.price.unitAmount,
      allowedNumberOfBranches: this.allowedNumberOfBranches,
      accessToAnalytics: this.accessToAnalytics,
      givesPremiumAccess: this.givesPremiumAccess,
      subscriptionPlanFeatures: this.subscriptionPlanFeatures.map((feature) => {
        return {
          subscriptionFeatureId: feature.subscriptionPlanFeature.id,
          title: "",
          supported: feature.supported,
        };
      }),
    };
  }
}
