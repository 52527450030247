import styled from "styled-components";

type isActive = {
  isActive: boolean;
};

export const NavLinkStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-left: ${({ isActive }: isActive) =>
    isActive ? "3px solid #7fcafa" : "3px solid transparent"};
  padding: 19px 24px;
  background: ${({ isActive }: isActive) =>
    isActive ? "#7fcafa1a" : "transparent"};

  &:hover {
    border-left: 3px solid #7fcafa;
    background: #7fcafa1a;
  }

  &:hover .linkName {
    color: #7fcafa;
  }

  &:hover path {
    fill: #7fcafa;
  }

  .linkName {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${({ isActive }: isActive) => (isActive ? "#7fcafa" : "#ffffff")};
    margin-left: 15px;
  }

  path {
    fill: ${({ isActive }: isActive) => (isActive ? "#7fcafa" : "#ffffff")};
  }
`;
