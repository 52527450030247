import {
  Login,
  TrailerMan,
  TrailerTypes,
  TrucksMan,
  Users,
  Businesses,
  PaymentSystem,
  Branches,
} from "../pages";

interface Route {
  path: string;
  component: React.ElementType;
  exact: boolean;
}

interface RouteConfig {
  fullLayout: boolean;
  routes: Route[];
}

const publicRoutes: RouteConfig = {
  fullLayout: true,
  routes: [
    {
      path: "/",
      component: Login,
      exact: true,
    },
  ],
};

const privateRoutes: RouteConfig = {
  fullLayout: false,
  routes: [
    {
      path: "/",
      component: TrucksMan,
      exact: true,
    },
    {
      path: "/trailer-manufacturers",
      component: TrailerMan,
      exact: false,
    },
    {
      path: "/trailer-types",
      component: TrailerTypes,
      exact: false,
    },
    {
      path: "/businesses",
      component: Businesses,
      exact: true,
    },
    {
      path: "/branches",
      component: Branches,
      exact: true,
    },
    {
      path: "/users",
      component: Users,
      exact: false,
    },
    {
      path: "/payment-system",
      component: PaymentSystem,
      exact: false,
    },
  ],
};

/**
 * generate routes based on token existance
 * @param token - string or undefined
 * @return privateRoutes or publicRoutes routes based on token existance
 */
export const generateRouterConfig = (token?: string): RouteConfig => {
  if (token) {
    return privateRoutes;
  }
  return publicRoutes;
};
